import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Spacer from '../components/Spacer';
import Page from '../components/Page';
import Input from '../components/input/Input';
import API from '../services/API';
import { useHistory } from "react-router-dom";
import { TailSpin	 } from  'react-loader-spinner'
import moment from 'moment';
import SessionHeader from '../components/compositions/SessionPageCompositions/SessionHeader.tsx';
import SessionBonusPanel from '../components/compositions/SessionPageCompositions/SessionBonusPanel.tsx';
import SessionRoutinePanel from '../components/compositions/SessionPageCompositions/SessionRoutinePanel.tsx';
import SessionDivider from '../components/compositions/SessionPageCompositions/SessionDivider.jsx';
import SessionWeekStart from '../components/compositions/SessionPageCompositions/SessionWeekStart.tsx';
import SessionBadgeRow from '../components/compositions/SessionPageCompositions/SessionBadgeRow.tsx';
const SessionPage = () => {
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(true);
    const [headerInfo, setHeaderInfo] =useState(null);
    const [patientData, setPatientData] = useState(null);
    const [sessionData, setSessionData] = useState(null);
    const [startWeek, setStartWeek]= useState(0);

    const dayName = useParams().dayName;
    const patientId = useParams().patientId;
	let history = useHistory();
    

    const CaptilizeWords=(sentence)=>{
        var words= sentence.split(" ");
        words.forEach((word,i) => {
            const firstLetter = word.charAt(0)

            const firstLetterCap = firstLetter.toUpperCase()
            
            const remainingLetters = word.slice(1)
            
            const capitalizedWord = firstLetterCap + remainingLetters
            words[i]= capitalizedWord;
        });
        return words.join(" ");
    }
    


    const breadcrumbLinks = () => {
        return [
            {
                label: "Patients",
                url: "/"
            },
            {
                label: (sessionData!==null?CaptilizeWords( sessionData.patient):"Patient"),
                url: `/patient/${patientId}`
            },
            {
                label: moment(dayName).format("MMMM DD YYYY"),
                url: window.location.pathname
            }
            
        ]
    }

    useEffect(()=>{
        if(patientData===null)
        {
            getPatientData(patientId);
        }
    },[])

    useEffect(()=>{
        console.log(sessionData)
       if(sessionData!==null){
       var head={}
       head.trail=breadcrumbLinks();
        head.body={};
        head.body.subheader="VET™ Session Summary";
        head.body.name=sessionData!==null?sessionData.patient:"Patient"
        head.body.buttons = [{title:"Back",click:toPatient,img:"/icons/flip-backward.svg"}]
        setHeaderInfo(head);
       }
    },[sessionData])

    const toPatient =()=>{
		goToPatient(patientId);
	}

    


	const goToPatient = (id, success=false) => {

		if(success)
		{
			localStorage.setItem("success","updated patient profile" );

		}
		history.push(`/patient/${id}/activity`);
	}

    const getPatientData = (id) => {
        console.log(`/sessions/?patient_id=${id}&date=${moment(dayName).format("YYYY-MM-DD")}`)
        API.get(`/sessions/?patient_id=${id}&date=${moment(dayName).format("YYYY-MM-DD")}`).then(response => {
            console.log(response.data)
          //setPatientData(response.data);
          setSessionData(response.data)
          setStartWeek(response.data.starting_plan_week)
          setLoading(false);
       
            
           
                    
          }).catch(error => console.log(error))
       
      }

      const BadgeWarning=()=>{
        return (
            <div className=" px-5 pb-5 justify-start items-start gap-3 inline-flex">
    <img className="w-5 h-5  relative flex-col justify-start items-start  flex" src="/icons/warning_icon.svg"/>
    <div className="text-amber-700 text-sm font-normal font-['Inter'] pt-0.5	 leading-tight">Sessions prior to 10th June 2024 cannot display badges in the timeline view.</div>
</div>
        )
      }

      var week= startWeek
      const events= sessionData&&sessionData.events!==null&&sessionData.events.length>0&&sessionData.events.map(e=>{
        switch(e.type)
        {
            case 'bonus_activity':
                var bonuses=[];
                for(var j=0;j<e.activities.length;j++){
                for(var i=0; i<e.activities[j].instances.length; i++){
                    e.activities[j].instances[i].name= e.activities[j].name;
                    e.activities[j].instances[i].type = e.activities[j].type;

                bonuses.push( <SessionBonusPanel activity={e.activities[j].instances[i]}/>)
                }
            }
                return bonuses
            case 'coaching_plan':
                week= e.plan_week
               return <SessionWeekStart sessionData={e}/>
                break;
            case 'personal_routine':
                return <SessionRoutinePanel sessionData={e} day={dayName} week={week}/>
                break;
            case 'session_badge':
                return <SessionBadgeRow sessionData={e}/>
                break;

        }
      })

   


  return (
    
    <Page errors={errors} headerObj={headerInfo!==null?headerInfo:{trail:breadcrumbLinks()}}  internal={true} noScroll={true}>
        <Spacer headerObj={headerInfo!==null?headerInfo:{trail:breadcrumbLinks()}} internal={true}/>
        {!loading&&sessionData!==null?
            <div id="body"  className={"w-full h-full flex-col inline-flex  justify-start  whitespace-nowrap overflow-x-hidden overflow-y-auto"}>
                <SessionHeader sessionData={sessionData}/>
                <SessionDivider label={moment(dayName).format("MMMM DD, YYYY")+" "+" (Week "+sessionData.starting_plan_week+")"}/>
                {moment(dayName).isBefore(moment("2024-6-10"))? BadgeWarning():<span></span>}
               <div className='overscroll-x-none px-5 gap-y-5 justify-center items-center inline-flex  flex-col  '>
                {events}


        </div>
            </div>
            :
            <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
	<TailSpin	
				height="100"
				width="100"
				color='grey'
				ariaLabel='loading'
			  />
			  <br /> <br />
			  <div/>
		  <h4 className="font-['Inter']">Loading Session Page...</h4>
	</div>
            }

    </Page>
  )
}

export default withRouter(SessionPage)