import React, { useState, useEffect, act } from 'react';
import { withRouter, useParams, Prompt } from 'react-router';
import PatientProfile from './PatientProfile';
import API from '../services/API';

import cookie from 'react-cookies';

import moment from 'moment';
import { TailSpin	 } from  'react-loader-spinner'
import NewPlan from '../components/compositions/EditCoachingPlanCompositions/NewPlan';
import DefaultZeroView from '../components/compositions/EditCoachingPlanCompositions/DefaultZeroView';
import CompletedCurrentWeekView from '../components/compositions/EditCoachingPlanCompositions/CompletedCurrentWeekView';
import CompletedFutureWeekView from '../components/compositions/EditCoachingPlanCompositions/CompletedFutureWeekView';
import CompletedPastWeekView from '../components/compositions/EditCoachingPlanCompositions/CompletedPastWeekView';
import PastWeekView from '../components/compositions/EditCoachingPlanCompositions/PastWeekView';
import FutureWeekView from '../components/compositions/EditCoachingPlanCompositions/FutureWeekView';
import CurrentWeekView from '../components/compositions/EditCoachingPlanCompositions/CurrentWeekView';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';






export const EditCoachingPlan = ({history}) => {

    const [gotData, setGotData ]= useState(false);

    const [stateView, setStateView ]= useState(null);

    const [init, setInit ]= useState(false);
	const [patientId, setPatientId] = useState(useParams().id);
    const [activities, setActivities] =useState(null)

    const [durations, setDurations] =useState(null)
    const [instanceID,setInstanceId]= useState(null)
    const [coachingPlans,setCoachingPlans]= useState(null);
    const [duplicatePlans,setDuplicatePlans]= useState(null);
    const [editPlanSettings, setEditPlanSettings ]= useState(false);
    const [isDirty, setIsDirty] = useState(null);
    const [updated, setUpdated] = useState(false);

    const [successes, setSuccess] = useState(null);
    const [settings, setSettings] = useState(null);

    const [errors, setErrors] = useState(null);
    const [weeks,setWeeks]= useState(null);

    const [currentWeek,setCurrentWeek]= useState(null);
    const [selectedWeek,setSelectedWeek]= useState(null);

    const [havePlan,setPlan] = useState(false);
    const [templatePlan,setTemplatePlan]= useState(null);
    const [timeZone,setTimeZone]= useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [coachingTracks, setCoachingTracks] = useState(null);
    const [trackPlan, setTrackPlans] = useState(null);
    const [PlanName,setPlanName]= useState("_")
    const [trackInstance,setTrackInstace]= useState(null)
    const [patientName,setPatientName]= useState(null)
    const [activityTypes,setActivityTypes]= useState({})
	  const [clientVersion,SetClientVersion ] = useState(null);
      const [revertedRoutine,setRevertedRoutine]= useState(null);
      const [revertedPlan,setRevertedPlan]= useState(null);


    const [personalRoutine,setPersonalRoutine]= useState(null);
    const [duplicateProtocol,setDuplicateProtocol]= useState(null);
    const [personalRoutineEnabled,setPersonalRoutineEnabled]= useState(false);
    const [bestPriority,setBestPriority]= useState(null);
    const [calAct,setCalAct]= useState(null);
    const [UICheck,setUICheck]= useState(false);
    const [forceLoading,setForceLoading]= useState(false);
    const [activityStats,setActivityStats] = useState(null);
    const [constPlan,setConstPlan]= useState(null);
    const [constRoutine,setConstRoutine]= useState(null)

    useEffect(() => {
      if(!activities )
      {
      GetProtocolData();
      }
      else if(activities )
      {
        var result = localStorage.getItem("week")
    if(result===null||result===undefined)
    {
         result=null   
         //console.log("no week found")
   
    }
	else
	{
        localStorage.removeItem("week");

        //console.log("found week "+result)
       // setSelectedWeek(parseInt( result));
       result=parseInt(result);

	}
          GetCurrentPlan(result);

      }
}, [activities]);

  useEffect(() => {
     
      if(init===true && editPlanSettings===false)
      {
          CreateNewPlan();
      }
      else if(init===true && editPlanSettings===true)
      {
         UpdatePlanSettings()
      }
     
   }, [init]);

   

   

   useEffect(() => {

       if(bestPriority!==null&& coachingPlans!=undefined && personalRoutine !==undefined&& activities!==undefined&&coachingTracks!==null&&duplicatePlans!==null&&duplicateProtocol!==null)
       {
        setGotData(true);
           SetUIFields();
           setStateView(renderState())
       }
       else
       {
        setStateView(renderState())

       }
   }, [coachingPlans,activities,personalRoutine, bestPriority,calAct, UICheck,weeks,forceLoading,activityStats,coachingTracks,duplicatePlans,duplicateProtocol,currentWeek,settings]);


   useEffect(() => {
    setStateView(renderState())

}, [selectedWeek]);

useEffect(() => {

}, [stateView]);


   useEffect(() => {

      if(isDirty)
      {
         //console.log('dirty'); 
      }
      else
      {
        redoState();
          
      }
      
  }, [isDirty]);


  

  

  useEffect(() => {

    //  window.scrollTo(0, 0);       
    //console.log(errors)
    if(errors===null){return;}
    if(!errors.endsWith("\n"))
    {
        setErrors(errors+"\n")
    }
    //er=errors
     
  }, [errors]);


  useEffect(() => {

    //  window.scrollTo(0, 0);       
    //console.log(successes)
    if(successes===null){return;}
    
    //er=errors
     
  }, [successes]);

  useEffect(()=>{
    if(activityStats===undefined)
    {
        GetProtocolData();
    }
  },[activityStats]);


 



    /////////////////////////////////////
    //  ASYNC COACHING PLAN FUNCTIONS  //
    ////////////////////////////////////

    const redoState=()=>{
        setUpdated(false);
        setStateView(renderState())
        GetCurrentPlan(selectedWeek);
        setForceLoading(false);
    }
   
    const ParseName=(name)=>{
      if(name.includes("CRPS")){
          if(name.includes("Lower"))
          {
              return "CRPS - Lower Back";
          }
          else{
              return "CRPS - Upper Extremity";
          }
      }
      else{
      return name.replace(/_|-|\\. /g, ' ');
      }
  }

  const UpdatePlan= async () => {
    setSuccess(null );

   // setErrors(null)

      ClearErrors();
      var temp =coachingPlans;
      setCoachingPlans(null);
      var newId=null
      for(var i =0 ; i< coachingPlans.length;i++)
      {
          var data = { patient : patientId, time_zone: temp[i].time_zone, start_date: temp[i].start_date.format("YYYY-MM-DD"), track_name: temp[i].track_name ,plan_title: temp[i].plan_title ,plan_week: temp[i].plan_week ,assignments: cleanDuration( temp[i].assignments.filter(n => n)),state: temp[i].state}
          if(JSON.stringify(data.assignments)!==duplicatePlans[i].assignments){
              if(currentWeek===i){
                  await API.post(`/coaching_plans/`, JSON.stringify(data)).then((response) => {

                      //console.log(response);
                      
                      newId= response.data.id;
                      
                        
                  }).catch(error => {
                      console.error(error)
                   //   setErrors(null);
                      setErrors(error.message);

                  })  
              }
              else
              {
                 
                  await API.put(`coaching_plans/${temp[i].id}/`, JSON.stringify(data)).then((response) => {
                      //console.log(response);
                  }).catch(error => {
                      console.error(error)
                      console.error(error.message)

                      setErrors(error.message);
                  })
              }
          }
      }
      
      
      var errorCheck=false;
      var elementSet=new Set();
     personalRoutine.assignments.forEach(element=>{
          if(element.Id!=="Breathing")
          {
              if (elementSet.has(element.Id))
              {
                  errorCheck=true;
              }
              else
              {
                  elementSet.add(element.Id)
              }
          }
      })
      if(errorCheck)
      {
       // setErrors(null);

          setErrors("error saving or retrieving my routine activities, please check activities and remove any duplicates and try saving again");
      }
      else{
      if((JSON.stringify(personalRoutine.assignments)!==duplicateProtocol) ||(JSON.stringify(  personalRoutine.enabled)!==personalRoutineEnabled    ))
      {
          var data ={patient : patientId, assignments:cleanDuration( personalRoutine.assignments),enabled:personalRoutine.enabled}
          await API.put(`/personal_routines/${personalRoutine.id}/`,JSON.stringify(data)).then((response) => {
          
          //console.log(response);

      }).catch(error => {
         
          console.error(error)
          console.error(error.message)
         // setErrors(null);

          setErrors(error.message);
      })
      }

  }   
      if(newId!==null)
      {
          API.post(`coaching_plans/${newId}/replace/`).then((response) => {
                //console.log("replacing week")
              //console.log(response);
              setUpdated(true);     

             redoState()
          }).catch(error => {
              console.error(error)
          //   setErrors(null);

              setErrors("replacing week")
          })
      }
      else
      {
        //console.log("ending loading")

        setSuccess("saved coaching plan changes" );
        
        redoState();
      }


  }

  const UpdatePlanSettings= async () => {
    setSuccess(null );

      ClearErrors();
      var temp =coachingPlans;

      setCoachingPlans(null);
      var week0def=temp[0].track_name!=='Default Week Zero';
      if(temp[0].track_name!=='Default Week Zero')
      {
          for(var i =0; i< coachingPlans.length;i++)
          {

              var data = { patient : patientId, time_zone: timeZone, start_date: temp[i].start_date.format("YYYY-MM-DD"), track_name: trackPlan[templatePlan].track_name ,plan_title: (i<=12?trackPlan[templatePlan].plans[i].title:"") ,plan_week: temp[i].plan_week ,assignments: cleanDuration(i<=12? ( FormatPlanAssignments(trackPlan[templatePlan].plans[i].assignments)):(FormatPlanAssignments(trackPlan[templatePlan].plans[12].assignments))),state: temp[i].state}
              if(currentWeek==i ){
                  await API.post(`/coaching_plans/`, JSON.stringify(data)).then((response) => {
                      //console.log(response);
                      var newId= response.data.id;
                          API.post(`/coaching_plans/${newId}/replace/`).then((response) => {
                              //console.log(response);
                          }).catch(error => {
                              console.error(error)
                            //  setErrors(null);

                              setErrors("replacing week")
                          })
                  }).catch(error => {
                      console.error(error)
                    //  setErrors(null);

                      setErrors(error.message);

                  })
              
              }
          
              else if(i>currentWeek)
                  {

                      await API.put(`coaching_plans/${temp[i].id}/`, JSON.stringify(data)).then((response) => {
                      }).catch(error => {
                          console.error(error)
                         // setErrors(null);

                          setErrors(error.message);
                      })
                  }
          }
      }
      else{
            for(var i =0; i< (temp.length>13?temp.length:13);i++)
          {

              if(currentWeek==i ){
               

                  var data = { patient : patientId, time_zone: timeZone, start_date: temp[i].start_date.format("YYYY-MM-DD"), track_name: trackPlan[templatePlan].track_name ,plan_title: trackPlan[templatePlan].plans[0].title ,plan_week: temp[i].plan_week ,assignments: cleanDuration(temp[i].assignments.filter(n => n)),state: temp[i].state}

                  await API.post(`/coaching_plans/`, JSON.stringify(data)).then((response) => {
                      var newId= response.data.id;
                          API.post(`/coaching_plans/${newId}/replace/`).then((response) => {
                          }).catch(error => {
                              console.error(error)
                              //setErrors(null);

                              setErrors("replacing week")
                          })
                  }).catch(error => {
                      console.error(error)
                   //   setErrors(null);

                      setErrors(error.message);

                  })
              
              }
              else if(i>currentWeek)
                  {
                      var data = { patient : patientId, time_zone: timeZone, start_date: new moment(temp[0].start_date).add(7*i,'days').format("YYYY-MM-DD"), track_name: trackPlan[templatePlan].track_name ,plan_title: trackPlan[templatePlan].plans[0].title ,plan_week: i ,assignments: cleanDuration(temp[i].assignments.filter(n => n)),state:"PENDING" }

                      await API.post(`/coaching_plans/`, JSON.stringify(data)).then((response) => {
                          //console.log(response);
                      }).catch(error => {
                          console.error(error)
                          setErrors(error.message);
                      })
                  }
          } 
      }
      if(personalRoutine!==null){
      var priorityprot= FormatPlanAssignments( trackPlan[templatePlan].default_personal_routine.assignments)
      var data ={patient : patientId, assignments: priorityprot, enabled:personalRoutine.enabled}
      data.assignments[0].CanSkip=false;

      await API.put(`/personal_routines/${personalRoutine.id}/`,JSON.stringify(data)).then((response) => {

      }).catch(error => {
         
          console.error(error)
          console.error(error.message)
          console.error(error)

          setErrors(error.message);
      })
      }
      else
      {
          var priorityprot= FormatPlanAssignments( trackPlan[templatePlan].default_personal_routine.assignments)

          var data ={patient : patientId, assignments:priorityprot}
          data.assignments[0].CanSkip=false;

          await API.put(`/personal_routines/`,JSON.stringify(data)).then((response) => {
  
          }).catch(error => {
              console.error(error)
              console.error(error.message)
  
              setErrors(error.message);
          })
      }
      if(instanceID!==null ){
          var name = ParseName(templatePlan)
          var data ={ track_name: name}

      await API.put(`/coaching_plan_track_instances/${instanceID}/`,JSON.stringify(data)).then((response) => {

      }).catch(error => {
          console.error(error)
          console.error(error.message)

          setErrors(error.message);
      })       
   } 
   if(!week0def){
   localStorage.setItem("success","Changed coaching plan settings" );
   }

   RefreshPage()
  // setSuccess("Changed coaching plan settings" );

  //redoState()
  
  }

  const FormatPlanAssignments =(arr) =>{
      var result=[];
      arr.forEach(element =>{
        if(element!==null&& element.hasOwnProperty("Id")){
          result.push({"Id": element.Id})
        }
        else
        {
            result.push({"Id": element})

        }
      })
      return result;
  }

  const CreateNewWeek= async()=>{
      if(editPlanSettings && DetectUnchangedSettings())
      {
          let dirtyText= "Warning!\n unsaved changes to plan, please save or remove changes before adding additional weeks"

          setErrors(dirtyText);
          return;  
      }
      let text = "Warning!\ncontinuing will create an additional week to your coaching plan that can only be removed by contacting your Karuna admin.\n\n press OK to proceed or Cancel to stop";
      if (window.confirm(text) === true)
      {
          
          var plans= trackPlan[templatePlan].plans;
          var lastWeek = coachingPlans[coachingPlans.length-1];
          var data = { patient : patientId, time_zone: lastWeek.time_zone, start_date: moment(lastWeek.start_date).add(7,'days').format("YYYY-MM-DD"),track_name: lastWeek.track_name ,plan_title: "additional week"+(lastWeek.plan_week+1).toString() ,plan_week: lastWeek.plan_week+1 , assignments: FormatPlanAssignments( plans[plans.length-1].assignments), state: "PENDING"};
          await API.post(`/coaching_plans/`, JSON.stringify(data)).then((response) => {
              //console.log(response);
              
          }).catch(error => {
              console.error(error)
              setErrors(error.message);
          })
          RefreshPage();      
      } 
     
      


  }

  const CreateNewPlan= async () => {
      var plans= trackPlan[templatePlan].plans;
      var priorityprot= FormatPlanAssignments( trackPlan[templatePlan].default_personal_routine.assignments)
      setPlanName(trackPlan[templatePlan].track_name)
        var week0Id=null
      for(var i =0; i< plans.length;i++)
      {
       
            var data = { patient : patientId, time_zone: timeZone, start_date: moment(startDate).add(7*i,'days').format("YYYY-MM-DD"),track_name: trackPlan[templatePlan].track_name ,plan_title: trackPlan[templatePlan].plans[i].title ,plan_week: i , assignments: FormatPlanAssignments( plans[i].assignments), state: "PENDING"};
         await API.post(`/coaching_plans/`, JSON.stringify(data)).then((response) => {
             //console.log(response);
             if(i===0)
             {
                 week0Id=response.data.id;
             }
             
         }).catch(error => {
             console.error(error)
             setErrors(error.message);
         })

      }

      if(week0Id!==null)
      {
        await API.post(`coaching_plans/${week0Id}/activate/`).then((response) => {
            //console.log(response);

        }).catch(error => {
            console.error(error)
            setErrors("errors starting week "+week0Id)

        })
      }
      var data ={patient : patientId, assignments:priorityprot}
      data.assignments[0].CanSkip=false;

      await API.post(`/personal_routines/`,JSON.stringify(data)).then((response) => {
          //console.log(response);

      }).catch(error => {
          console.error(error)
          console.error(error.message)

          setErrors(error.message);
      })


     

    // GetProtocolData();
   // GetCurrentPlan();
    //redoState();
    RefreshPage();
  }

  const EndPlan = async () => {
      var week = currentWeek+1;
      if(week>=coachingPlans.length)
      {
          await API.post(`coaching_plans/${coachingPlans[coachingPlans.length-1].id}/complete/`).then((response) => {
              //console.log(response);
          }).catch(error => {
              console.error(error)
              setErrors("errors ending week "+week.toString())

          })
      }
          
      await API.post(`coaching_plan_track_instances/${trackInstance.id}/complete/`).then((response) => {
          //console.log(response);
      }).catch(error => {
          console.error(error)
          setErrors("errors ending track instance")

      })
      RefreshPage();
      
  }

  const StartWeek= async () => {

      if(isDirty){
     
          if(editPlanSettings && DetectUnchangedSettings()){
              //console.log("change in settings");
              setErrors("unsaved changes to plan, please save or remove changes before starting a new week");
              return;  
          }
          else if (!editPlanSettings && DetectUnchangedAssignemnts())
          {
              //console.log("change in plan")
              setErrors("unsaved changes to plan, please save or remove changes before starting a new week");
              return;  
          }
          else{
              //console.log("no changes")
              ClearErrors();
          }   
      }
      setIsDirty(true)
      var tempplans= coachingPlans
      setForceLoading(true);

      setCoachingPlans(null);
  
      setCurrentWeek(currentWeek+1);

      var week = currentWeek+1;
      if(week==0){
         
          await API.post(`coaching_plans/${tempplans[week].id}/activate/`).then((response) => {
              //console.log(response);
              localStorage.setItem("success","Week "+week+ " started" );
              RefreshPage();

          }).catch(error => {
              console.error(error)
              setErrors("errors starting week "+week.toString())
redoState()
          })
      }
      else
      {
              var prevWeek=week-1;
              await API.post(`coaching_plans/${tempplans[prevWeek].id}/complete/`).then((response) => {
                  API.post(`coaching_plans/${tempplans[week].id}/activate/`).then((response) => {
                      //console.log(response);
                      localStorage.setItem("success","Week "+week+ " started" );

                      RefreshPage();
                    //  setIsDirty(false);
  
                  }).catch(error => {
                      console.error(error)
                      setIsDirty(false);
  
                      setErrors("errors starting week "+week.toString())
                  })
                  //console.log(response);
              }).catch(error => {
                  console.error(error)
                  setIsDirty(false);

                  setErrors("errors starting week "+week.toString())
              })
             
              
      }

      

  }

//////////////////
  //   Functions  //
 //////////////////

 const GetProtocolData= () => {


    API.get(`/activity_stats/?patient_id=${patientId}`).then(response => {

        let actStats={}
        console.log(response.data)
        if(!response.data.hasOwnProperty('error')){
        setSettings(response.data.activity_stats)
        for (const [key, value] of Object.entries(response.data.activity_stats)) {
           
            actStats[key]={}
            actStats[key]["name"]= value.name;
            actStats[key]["type"]= value.type;
            actStats[key]["duration"]= value.Settings.Duration.Value;
            actStats[key]["duration_increment"]= value.Settings.DurationIncrement.Value;
            actStats[key]["assigned"]= value.assigned;
            actStats[key]["completions"]= value.completions;
            actStats[key]["hasOptions"]= Object.entries(value.Settings).length>2
            if(value.weekly_stats!==undefined)
            {
                actStats[key]["weekly_stats"]= value.weekly_stats
            }
            if( actStats[key]["hasOptions"]===true)
            {
                let names =[];
                let descriptions= []
                Object.entries(value.Settings).forEach(([name, value]) => {
                    if(name!=="Duration"&&name!=="DurationIncrement")
                    {
                        descriptions.push(value.Description)

                        names.push(name);

                    }
                 });


                actStats[key]["OptionNames"]=names;
                actStats[key]["OptionDescriptions"]=descriptions;
            }



        }
        console.log(actStats)
        setActivityStats(actStats)
    }
    else
    {
        setActivityStats({})
        setSettings({})
    }
          
    
      }).catch(
          error => {
              //console.log(errors)
              console.error(error)

              setErrors(error);
              setErrors(error.message)
          }
          );

  

  API.get(`vetkitassignments/?patient=${patientId}`).then(response=>{
      var vetdate =null;
      if(response.data.results[0]){
          if(response.data.results[0].date_assigned!==undefined && response.data.results[0].date_assigned!==null){
                      vetdate= new moment(response.data.results[0].date_assigned,'YYYY-MM-DD')
          }
      }
      if(vetdate!==null){
          if(vetdate.isAfter(new moment("2022-10-19"))){
              actFilter=[];
          }
      }

      
    
      API.get(`/protocols`).then(response => {
     
      setActivities(response.data.results.filter(event => (event.activities.length==1 && !event.id.includes("Pro")  &&  (!actFilter.includes( event.id)) )) )
      var dur={}
      var act =response.data.results.filter(event => (event.activities.length==1 && !event.id.includes("Pro")  &&  (!actFilter.includes( event.id)) ))
      act.forEach(element => {
          var d= {"Duration": element.activities[0].Duration,"Increment":element.activities[0].DurationIncrement}
          dur[element.id]=d
      });
      setDurations(dur)
      
      }
      ).catch(
          error => {
            console.error(error)

              setErrors(error);
              setErrors(error.message)
          }
          );
  }).catch(
      error => {
        console.error(error)

          setErrors(error);
          setErrors(error.message)
      }
      );
      
}

function capital_letter(str) 
{
  str = str.split(" ");

  for (var i = 0, x = str.length; i < x; i++) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1);
  }

  return str.join(" ");
}

const GetCoachingTrackData= () => {
  API.get(`/coaching_plan_tracks`).then(response => {
     var tracks={}
     var plans=[]
     
     response.data.results.forEach((element,i) => {
         tracks[element.id]=element;
         plans.push({id: i, value: element.id,name: element.track_name})

     })
     setCoachingTracks(plans);
     setTrackPlans(tracks);

  }
  ).catch(
      error => {
          setCoachingTracks([])
          setTrackPlans({})
          console.error(error)

          setErrors(error.message);
      }
      );
}

const SetName= () => {
  API.get(`/patients/${patientId}`)
  .then(response => {
      if(response.data.first_name && response.data.last_name)
      {
          setPatientName(response.data.first_name +" "+ response.data.last_name)
      }
      if("client" in response.data)
      {
          SetClientVersion(response.data.client.version);
      }
      
      
  }).catch(error => 
      {
          //console.log(error);
          console.error(error)

          setErrors(error.message);
      }
  );

}

API.interceptors.response.use(response => {
return response;
}, error => {
 if (error.response.status === 401) {
const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";

//console.log(401)
cookie.remove('token');
  cookie.remove('csrftoken', { path: '/' });
  cookie.remove('role', { path: '/' });
  cookie.remove('user', { path: '/' });
localStorage.clear();
history.push('/login');
 }
 return error;
});

const GetCurrentPlan = (week=null) =>{
  
  API.get(`/coaching_plan_track_instances/?patient=${patientId}`).then(response => {
    console.log(response)

      var sortedTracks= response.data.results.sort((a, b) =>(Number.parseInt(a.id) > Number.parseInt(b.id)) ? -1 : 1)
     
      setTrackInstace(sortedTracks[0])
      var track= sortedTracks[0]

      
      setInstanceId(track!==undefined?track.id:null)
      if(track!==undefined){
         
              API.get(`/personal_routines/?patient=${patientId}`).then((response) => {
              
              if(response.data.results.length>0)
              {
              var index = response.data.results.length-1;
              
              response.data.results= response.data.results.sort((a, b) =>(Number.parseInt(a.id) > Number.parseInt(b.id)) ? 1 : -1)
              var priorityProt={id: response.data.results[index].id,patient: response.data.results[index].patient,assignments: response.data.results[index].assignments, state:response.data.results[index].state,enabled:response.data.results[index].enabled};
              setPersonalRoutine(priorityProt);
              setConstRoutine(JSON.parse(JSON.stringify(( priorityProt))))
              setRevertedRoutine( JSON.stringify(response.data.results[index].assignments))
              if(priorityProt.assignments.length>0){
              setCalAct(priorityProt.assignments[0].Id);
              }
              setPersonalRoutineEnabled(JSON.stringify(priorityProt.enabled));
              var errorCheck=false;
              var elementSet=new Set();
              priorityProt.assignments.forEach(element=>{
                  if(element.Id!=="Breathing")
                  {
                      if (elementSet.has(element.Id))
                      {
                          errorCheck=true;
                      }
                      else
                      {
                          elementSet.add(element.Id)
                      }
                  }
              })
              if(errorCheck)
              {
                  setErrors("error saving or retrieving my routine activities, please check activities and remove any duplicates and try saving again");
              }
              if(priorityProt.assignments.length!==7 && track.track_name!=="Default Week Zero")
              {
                  setErrors("error saving or retrieving activities, My routine seems to have an irregular amount of activities assigned to it, please check your connection and refresh the page, if issue persists please fill in any missing activities or contact admin")

              }
              if(response.data.results[index].assignments.length>0&&response.data.results[index].assignments[0].hasOwnProperty("CanSkip")){
              delete response.data.results[index].assignments[0].CanSkip;
              }
              var duplicate=    JSON.stringify( response.data.results[index].assignments.filter(n => n))
          
              
              setDuplicateProtocol(duplicate);
              }

              
              }).catch(error => {
                  console.error(error)
                  console.error(error.message)

                  setErrors(error.message);
              })
          //}
      API.get(`/coaching_plans/?patient=${patientId}&track_instance=${track.id}`).then(response => {
        
          var plans=[]
          var weeks=[]
          let sortedplans = response.data.results.sort((a, b) =>(Number.parseInt(a.id) > Number.parseInt(b.id)) ? 1 : -1)
          sortedplans .forEach(element => {
            if(!weeks.includes(element.plan_week)){

                if(element.state!=="Replaced"){
                    let ass= element.assignments

                plans.push({id : element.id, time_zone : element.time_zone,start_date: moment(element.start_date),track_name : element.track_name,plan_title : element.plan_title,plan_week : element.plan_week, assignments : ass, state: element.state})
                weeks.push(element.plan_week)
                }
            }
            else
            {
             
                if(plans[parseInt(element.plan_week)].state!=="Active"){
                    plans[parseInt(element.plan_week)]={id : element.id, time_zone : element.time_zone,start_date: moment(element.start_date),track_name : element.track_name,plan_title : element.plan_title,plan_week : element.plan_week, assignments : element.assignments, state: element.state}
                }
            }
          });
          if(plans.length>0)
          {
              let sorted = plans.sort((a, b) =>(Number.parseInt(a.plan_week) > Number.parseInt(b.plan_week)) ? 1 : -1)
              var duplicate = FormatPlan( sorted.map((_arrayElement) => Object.assign({}, _arrayElement)));  
              for(var i=0; i< duplicate.length;i++)
              {
                  duplicate[i].assignments= JSON.stringify(duplicate[i].assignments.filter(n => n))
              }

              let ratingDict={}
              
              API.get(`/personal_routine_progress/?patient=${patientId}`)
  .then(response => {
    //console.log(response.data.results)
            if(response.data.results.length>0)
            {
                let filtered = response.data.results.filter((p)=> p.track_instance===track.id)
                response.data.results.forEach(element=>{
                if( element.track_instance===track.id && element.is_complete)
                {
                    if(element.plan_week in ratingDict)
                    {

                        if(element.rating!==undefined&&element.rating.score!==undefined)
                        {

                            var tempCount = ratingDict[element.plan_week].count;
                            if(element.rating.score>ratingDict[element.plan_week].rating.score)
                            {
                            ratingDict[element.plan_week]= element;

                            }
                            element.count=tempCount+1;



                        }
                        else
                        {
                            var tempCount = ratingDict[element.plan_week].count;

                            if(ratingDict[element.plan_week].rating===undefined)
                            {
                                if(ratingDict[element.plan_week].scores.length<=element.scores.length)
                                {
                                    element.count=0;
                                    element.rating={}
                                    element.rating.score=0
                                    ratingDict[element.plan_week]=element;

                                }

                            }
                            ratingDict[element.plan_week].count++;

                        }
                    }
                    else
                    {
                        
                        if(element.rating!==undefined&& element.rating.score!==undefined)
                        {
                            element.count=1;
                            ratingDict[element.plan_week]= element;

                        }
                        else
                        {
                            element.count=0;
                            element.rating={}
                            element.rating.score=0
                            ratingDict[element.plan_week]= element;
                        }
                    }

                }
                

                })
                if(bestPriority==null||Object.keys(bestPriority).length===0||Object.keys(bestPriority).length < Object.keys(ratingDict).length){
                    setBestPriority(ratingDict)
                    }
            }
          else
          {
              setBestPriority({})

          }
    
  })
  .catch(
    error => {
              if(bestPriority==null){
              setBestPriority({})
              }

    setErrors("error retrieving karuna ratings")
    //console.log(error)

    }


    );
              setDuplicatePlans(duplicate);

              setCoachingPlans(FormatPlan(sorted));
              setConstPlan(JSON.parse(JSON.stringify(( FormatPlan(sorted)))))
              setRevertedPlan(JSON.stringify(FormatPlan(sorted)))
              //console.log(sorted)
              if(sorted[0].track_name!=="Default Week Zero"){
              setPlan(true);
              }
              else{
                setEditPlanSettings(true);
              }
              SetWeek(sorted,week);
              setWeekOptions(sorted)
              GetCoachingTrackData();
          }
          else{
              SetName();
              GetCoachingTrackData();
              setCurrentWeek(-1);
              setSelectedWeek(-1);

              setCoachingPlans([])
              setBestPriority({})
          }
          
      }
    ).catch(
        error => {
           //console.log(error)
           console.error(error)

           setErrors(error.message)
        }
      );
      }
      else{
          SetName();
          GetCoachingTrackData();
          setCurrentWeek(-1);
          setSelectedWeek(-1);

          setCoachingPlans([])
          setBestPriority({})
      }
  })
  .catch(
      error => {
         //console.log(error)
         console.error(error)

         setErrors(error.message)
      }
    );    

}


const HandleSubmit = (event) => {
  event.preventDefault();
  //console.log(!havePlan)
  if(!havePlan )
  {
       
      if(startDate!==null && templatePlan!==null &&timeZone!==null){
        //console.log("init")
          InitializeFullPlan();
      }
      else
      {
        //console.log("error")

          setErrors("missing value to create coaching plan");
          return;
      }
  }
  else if(editPlanSettings)
  {
      

      if( templatePlan!==null &&timeZone!==null){

          InitializeFullPlan();

          }
          else
          {
              setErrors("missing value to update coaching plan settings");
              return;
          }       
    }
  else{
     var errorMessage="";
     errorMessage= VerifyPlan(errorMessage)

     if(errorMessage!=="")
     {
        //console.log(errorMessage)
        //console.log(errors)
       // ClearErrors();
       console.error(errorMessage.trim())

         setErrors(errorMessage.trim());

     }
     else
     {
         if(DetectUnchangedAssignemnts()){
          //console.log("update")
          UpdatePlan();
         }
          window.scrollTo({top: 0, behavior: 'smooth'});

     }

  }
  
}
const camelCaseParser=(text)=>{
  return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
}





const getStateColor=(state)=>
{
    switch(state)
    {
        case "Active":
            return 0;
        case "Pending":
            return 1;
        case "Completed":
            return 2;
        case "Interrupted":
            return 3;
        case "Cancelled":
            return 3;
        default:
            return 0;

    }
}

const setWeekOptions=(plans)=>{
    var temp=[]
    plans.forEach((e,i)=>{
        temp.push(
            {
                id: i,
        name: "Week "+i,
        value: i,
        badge:{value:(e.state==="Active"?"Current":e.state),color:getStateColor(e.state)}
            }
        )

    })
    setWeeks(temp)

}

const SetWeek= (plans, theWeek=null) => {
     API.get(`/patients/${patientId}`)
     .then(response => {
         if(response.data.first_name && response.data.last_name)
         {
             setPatientName(response.data.first_name +" "+ response.data.last_name)
         }
         if("client" in response.data)
      {
          SetClientVersion(response.data.client.version);
      }
      //console.log(response.data)
         if(response.data.coaching_plan!==undefined){
          
          var week = response.data.coaching_plan.plan_week
            var sweek=week;
            if(theWeek!==null)
            {
                sweek=theWeek
            }
          if(plans[week].track_name!=="Default Week Zero"){
          setCurrentWeek(week);
          setSelectedWeek(sweek)
          setTimeZone(plans[sweek].time_zone)
          setTemplatePlan(plans[sweek].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'))
          setPlanName(plans[sweek].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'))
          }
          else{

              setCurrentWeek(week);
              setSelectedWeek(sweek)

              setTemplatePlan('Upper_Extremity');
              setTimeZone(timeZones[0].value);
          
          
          setPlanName(plans[sweek].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'))
          }
         }
         else
         {
             if(plans[0].state!=="Completed" && plans[0].state!=="Cancelled" &&plans[0].state!=="Interrupted" )
             {

             setCurrentWeek(-1);
             setSelectedWeek(-1);
             setTemplatePlan(plans[0].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'))
             setPlanName(plans[0].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'))
             setTimeZone(plans[0].time_zone)

             }
             else{
                var w= plans.length
                for(var i=0;i<plans.length;i++){
                    if(plans[i].state!=="Completed")
                    {
                        w=i;
                        break;
                    }
                }


                 setCurrentWeek(w);
                 setSelectedWeek(w)
                 setTemplatePlan(plans[plans.length-1].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'))
                 setPlanName(plans[plans.length-1].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'))
                 setTimeZone(plans[plans.length-1].time_zone)

             }
         }
     }).catch(error => 
         {
             //console.log(error);
             console.error(error)

             setErrors(error.message);
         }
     );

 }

 const SetUIFields= () => {
      setInit(false);
     if(coachingPlans.length>0)
     {
      
      if(coachingPlans[0].track_name!=="Default Week Zero"){
        setStateView(renderState())
      }
      else
      {
        if(coachingTracks!==null){
        setTemplatePlan(coachingTracks[0].value)
        setTimeZone(timeZones[0].value)
        
        }
        else{
            setTemplatePlan('Upper_Extremity');
            setTimeZone(timeZones[0].value);

        }

      }


     }
     else
     {
        

        setTemplatePlan(coachingTracks[0].value)
      setTimeZone(timeZones[0].value)
     }
   
 }


 const FormatWeek= (week,proto,act) => {
      var newWeek=[]
      let y=0;
      if(proto && act){
         week.forEach(element => {
           
            if( element!==null&& element.Settings===undefined && activityStats!==null)
            {
               
                    element.Settings={}
                    element.Settings.Duration=activityStats[element.Id].duration;

            }

            newWeek.push(element)
            
            

             
         });
         while(newWeek.length<4)
         {
             newWeek.push(null);
         }
     }
     return newWeek;
 }

 const FormatPlan= (plans) => {
     var newPlan=[]
     var actList = activities && activities.map(proto => (proto.id));
     for (let x = 0; x < plans.length; x++) 
     {
         plans[x].assignments=FormatWeek(plans[x].assignments,[],actList)
         newPlan.push(plans[x])
     }
      return newPlan;   
         
 }

 const ForceSetupPage= () => {
      setCoachingPlans([])
      setPlan(false)
      setTemplatePlan(coachingTracks[0].value)
      setTimeZone(timeZones[0].value)
     
      setCurrentWeek(-1)
      setSelectedWeek(-1)

  }

  const RefreshPage= () => {

      window.location.reload();
  }

  const InitializeFullPlan= () => {
      if(!editPlanSettings)
      {
      setCoachingPlans(null);
      }
      else
      {
          var plans= trackPlan[templatePlan].plans;
          var temp = coachingPlans;
        
          if(temp[0].track_name!=='Default Week Zero')
          {
              if(coachingPlans[currentWeek!==-1?currentWeek:0].track_name!==trackPlan[templatePlan].track_name )
              {
                  
                  for(var i =0 ;i< plans.length;i++)
                  {
                      temp[i].assignments= plans[i].assignments;
                  }
                 setCoachingPlans(temp);

                  setPlan(true)
                  setInit(true);
                  return;
              
              }
              else
              {
                  if(timeZone!==coachingPlans[currentWeek!==-1?currentWeek:0].time_zone){

                      setPlan(true)
                      setInit(true);
                      return;
                  }
                  else
                  {
                      setInit(false);
                      setIsDirty(false);
                      return;
                  }
              }
          }
          else
          {
                  var plans= trackPlan[templatePlan].plans;
                  var temp = coachingPlans;
                 
                  for(var i =1 ;i<(temp.length>13?temp.length:13);i++)
                  {
                      var obj =Object.create(temp[0])
                      temp.push(obj)
                      temp[i].assignments= plans[i].assignments;

                  }

                  setCoachingPlans(temp);

                  setPlan(true)
                  setInit(true);
                  return;
          }
      }

      setPlan(true)
      setInit(true);
      
      
  }

  const SetStartDateDirty = (start) =>{
      setStartDate(start)
      if(moment(start).format("MM-DD-YYYY")!==moment(new Date()).format("MM-DD-YYYY"))
      {
          setIsDirty(true);
      }
      else
      {
          setIsDirty(false);
      }
  }
 
  


  
  const GoToCurrentWeek= () => {
      ChangeWeek(currentWeek);
  }

  


const VerifyDurations =(arry,week)=>{

  var message="";
  arry.forEach(element=>{
      
      if(element!==null && element.Duration!==undefined)
      {
          if(isNaN( element.Duration))
          {
              message+=", "+element.Id+" has no duration set"
          }
      }
  })

  if(message!=="")
  {
      message = "duration Error in week: "+week.toString()+message+`\n`
  }
  return message;

}    
const VerifyUnique= (arry, week) => {
  var message="";
  var ids=[];
  arry.forEach(element=>{
      if(element!==null)
      {
          ids.push(element.Id)
      }
  })
  const uniqueElements = new Set(ids);

  const filteredElements = arry.filter(item => {
      if (item!==null &&item.Id!== undefined && uniqueElements.has(item.Id)) {
          uniqueElements.delete(item.Id);
      } else {
          if(item!==null){
          message+= ", "+camelCaseParser( item.Id)
          }
      }
  });

  if(message!=="")
  {
      message = "duplicate in week "+week.toString()+message+`\n`
  }
  return message;
  
}


const UpdateBonusAcitivtyValue= (value,i,j) => {

  if(value){
    if(coachingPlans[i].assignments[j]!==null)
    {
        if(coachingPlans[i].assignments[j].Id===value){return;}
    }
   coachingPlans[i].assignments[j]={"Id":value,"Settings":{"Duration":settings[value].Settings.Duration.Value}}
  }
  else
  {
       coachingPlans[i].assignments[j]=null

  }
  setCoachingPlans(coachingPlans)
}

const UpdateBonusTimerValue= (value,id,i,j) => {

    let settings = coachingPlans[i].assignments[j].Settings

    if(settings===undefined)
    {
        settings={"Duration":value}
    }
    else
    {
        if(settings.Duration!==undefined)
        {
            settings.Duration=value
        }
        else
        {
            settings["Duration"]=value
        }
    }
 coachingPlans[i].assignments[j]={"Id":id,"Settings":settings}
  setCoachingPlans(coachingPlans)
}

const UpdatePriorityTimerValue=(value,id,i,j)=>
{
   if(value!==null&&value!==undefined&&!isNaN(value)){

    var temp= personalRoutine;
    
  temp.assignments[j]?temp.assignments[j]["Settings"]["Duration"]=value:temp.assignments[j]={"Id":id,"Settings":{"Duration":value}}
  setPersonalRoutine(temp)
   }
  
}

const UpdatePriorityValue= (value,i,j) => {
  if(value!==undefined&&value!==null&&value!==NaN)
  {

     if(personalRoutine) {
       if(personalRoutine.assignments[j].Id===value){return ;}
        personalRoutine.assignments[j]={"Id":value,"Settings":{"Duration":settings[value].Settings.Duration.Value}}
    }
     
     
  }
 
  if(j===0)
  {
      setCalAct(value)
  }
  else{
      setUICheck(!UICheck);
  }
  setPersonalRoutine(personalRoutine)
}

const UpdateBonusOptions=(name,value,i,j)=>{

   
    
    if(coachingPlans[i].assignments[j].Settings!==undefined&&coachingPlans[i].assignments[j].Settings[name]!==undefined)
    {
        coachingPlans[i].assignments[j].Settings[name]=value;
    }
    else
    {
        if(coachingPlans[i].assignments[j].Settings===undefined)
        {
            coachingPlans[i].assignments[j].Settings={};
        }
        coachingPlans[i].assignments[j].Settings[name]=value;
    }  
    setCoachingPlans(coachingPlans);


}

const UpdateRoutineOptions=(name,value,i,j)=>{
   
    if(personalRoutine.assignments[j]!==undefined&&personalRoutine.assignments[j].Settings!==undefined&&personalRoutine.assignments[j].Settings[name]!==undefined)
    {
        personalRoutine.assignments[j].Settings[name]=value;
    }
    else
    {
        if(personalRoutine.assignments[j].Settings===undefined)
        {
            personalRoutine.assignments[j].Settings={};
        }
        personalRoutine.assignments[j].Settings[name]=value;
    }
    setPersonalRoutine(personalRoutine)

}

const VerifyUniquePersonalRoutine=(arry)=>{
  var message="";
  var ids=[];
  arry.forEach(element=>{
      if(element!==null)
      {
          ids.push(element.Id)
          
      }
  })
  const uniqueElements = new Set(ids);

  const filteredElements = arry.filter(item => {
      if (item!==null &&item.Id!== undefined && uniqueElements.has(item.Id)) 
      {
          uniqueElements.delete(item.Id);
      } else 
      {
          if(item!==null &&item.Id!=="Breathing")
          {
              message+= ", "+camelCaseParser( item.Id)
          }
      }
  });

  if(message!=="")
  {
      message = "duplicate in My Routine "+message+`\n`
  }
  return message;

}


const VerifyPlan= (errorMessage) => {

  errorMessage+=VerifyUniquePersonalRoutine(personalRoutine.assignments)

  for(var i =0; i< coachingPlans.length; i++)
  {
      errorMessage+= VerifyUnique(coachingPlans[i].assignments,i);
      errorMessage+=VerifyDurations(coachingPlans[i].assignments,i);
   
  }

  return errorMessage;

}



const DetectEmptyAssignments = (assign) => {
  
      if(assign.filter(n => n).length<1)
      {
          return true
      }
      else
      {
          return false

      }

  
}



const DetectUnchangedAssignemnts= () => {

   
    var emptyDuplicatePlans=true;
    if(!gotData){return false;}
    if(coachingPlans===null&&duplicatePlans===null){return false;}
    if(personalRoutine===null&&duplicateProtocol===null){return false;}
    if(coachingPlans.length===0&&duplicatePlans.length===0){return false;}
    if(personalRoutine.length===0&&duplicateProtocol.length===0){return false;}
    for(var i =0; i < coachingPlans.length;i++)
        {
            if(duplicatePlans[i].assignments.length!==0&&JSON.stringify(coachingPlans[i].assignments.filter(n => n)).length!==0)
            {
                emptyDuplicatePlans=false;
            }
      
        }
    if(emptyDuplicatePlans){return false;}
  for(var i =0; i < coachingPlans.length;i++)
  {
        if(duplicatePlans[i].assignments!==JSON.stringify(coachingPlans[i].assignments.filter(n => n)))
      {
          return true
      }

  }

  if(JSON.stringify( personalRoutine.enabled)!==personalRoutineEnabled){
      setIsDirty(true)
      return true;
  }
  var cleanedRoutine= personalRoutine.assignments;


  if(JSON.stringify(cleanedRoutine)!==duplicateProtocol)
  {
    return true;
}
  return false
}

const ClearErrors= () => {
  setErrors(null);
}

const DetectUnchangedSettings= () => {
  if(PlanName!== templatePlan || timeZone!==coachingPlans[currentWeek!==-1?currentWeek:0].time_zone)
  {
      return true
  }
  return false;
}

const DetectUnchangedCreateSettings=()=>
{

  if(templatePlan!==null || timeZone!==null  || moment(startDate).format("MM-DD-YYYY")!==moment(new Date()).format("MM-DD-YYYY"))
  {
      return true
  }
  return false;
}






  ////////////////////////////////
  //    NONCONSTANT VARIABLES   //
  ///////////////////////////////
  

 

  
 
  ///////////////////////
  //    CONSTANTS     //
  /////////////////////

  var actFilter = []

  
  const timeZones =[{ id:0, value: "US/Pacific", name:"Pacific (PST)"},{ id:1,value: "US/Arizona", name:"Arizona (AST)"},{ id:2,value: "US/Mountain", name:"Mountain (MST)"},{ id:3, value: "US/Central", name:"Central (CST)"},{id:4, value: "US/Eastern", name:"Eastern (EST)"}]


 const  cleanDuration=(vals)=>{
      var newVals=[]
      vals.forEach(val=>{
         if(val!==null)
         {
            if(val.Id!==undefined&& activityStats[val.Id].duration_increment===-1)
            {
                delete val.Settings
            }
            if(val.DurationIncrement!==undefined)
            {
                delete val.DurationIncrement
            }
            newVals.push(val)
         }
      })
      return newVals;

  }

  const TogglePersonalRoutine=()=>{
      if(personalRoutine){
          personalRoutine.enabled=!personalRoutine.enabled
          setPersonalRoutine(personalRoutine)
          SetUIFields();

          
      }
  }



  const UnstartedCheck=()=>{
    if(coachingPlans===null||coachingPlans===undefined){
        return;
    }
    if(coachingPlans[0].state==="Pending"){
    
    StartWeek();
    }
  }

  ///////////////////////
  // window overloads //
  /////////////////////

  window.onbeforeunload = confirmExit;
  function confirmExit(event) {
      var messageText = "Are you sure you want to leave? you have unsaved changes"
      
      var notdirtycheckbox=DetectUnchangedAssignemnts();
      

      if(!isDirty &&!notdirtycheckbox)
      {
          messageText=""
      }
      if (messageText !== ""){
          if(editPlanSettings && DetectUnchangedSettings()){
              //console.log("change in settings");
              return true;
          }
          else if (!editPlanSettings && DetectUnchangedAssignemnts())
          {
            
              //console.log("change in plan")
              return true;
          }
          if(personalRoutine )
          {
              if(!notdirtycheckbox)
              {
                  //console.log("unsaved status for my routine enabled")

              }
          }
          else if (!editPlanSettings && DetectUnchangedCreateSettings())
          {
              //console.log("change in CreatePlan")
              return true; 
          }
          else{
              //console.log("no change")
              return void (0);
          }
      }
      else
      {
          return void (0);
      }
  };

  const SubmitToUpdatePlanSettings=(template)=>{
   
    if(template===undefined){return;}
    
    if(template===templatePlan){return;}
    if(isDirty){
        let dirtyText= "Error! unsaved changes to plan, please save or remove changes before adding additional weeks"
        setErrors(dirtyText);
        return; 
        }

    var text="Confirm Coaching Plan Track Change\nAre you sure you want to edit the current coaching plan track? Any custom changes you have made to activity assignments in current or future weeks will be replaced"
    if(window.confirm(text) === true){
            setTemplatePlan(template)
            setEditPlanSettings(true);
            var plans=  trackPlan[template].plans;
            var temp = coachingPlans;
            if(temp[0].track_name!=='Default Week Zero')
          {
            
              if(coachingPlans[currentWeek!==-1?currentWeek:0].track_name!==trackPlan[template].track_name )
              {
                  
                  for(var i =0 ;i< plans.length;i++)
                  {
                      temp[i].assignments= FormatPlanAssignments( plans[i].assignments);
                  }
                 setCoachingPlans(temp);

                  setPlan(true)
                  setInit(true);
                  return;
              
              }
              else
              {
                  if(timeZone!==coachingPlans[currentWeek!==-1?currentWeek:0].time_zone){

                      setPlan(true)
                      setInit(true);
                      return;
                  }
                  else
                  {
                      setInit(false);
                      setIsDirty(false);
                      return;
                  }
              }
          }
          else
          {
                  var plans= trackPlan[template].plans;
                  var temp = coachingPlans;
                  for(var i =1 ;i<(temp.length>13?temp.length:13);i++)
                  {
                      var obj =Object.create(temp[0])
                      temp.push(obj)
                      temp[i].assignments= FormatPlanAssignments(plans[i].assignments);

                  }


                  setCoachingPlans(temp);

                  setPlan(true)
                  setInit(true);
                  return;
          } 
        } 
    {
        setTemplatePlan(templatePlan)
    }
  }

  const ChangeWeek=(week)=>{
    if(selectedWeek===null){return;}
    if(selectedWeek===week){return;}
    if(DetectUnchangedAssignemnts()===true)
    {
        var text= "Warning!\nyou have unsaved changes, changing the week you are looking at will result in the loss of these change"
        if (window.confirm(text) === true)
        {
            var temp= JSON.parse(revertedRoutine)
            var temp2= JSON.parse(revertedPlan);
            personalRoutine.assignments=temp;
            coachingPlans[selectedWeek].assignments=temp2[selectedWeek].assignments;
            setPersonalRoutine(personalRoutine);
            setCoachingPlans(coachingPlans);
            setSelectedWeek(week)

        }
        else{
            setStateView( renderState());

        }
    }
    else{
    setSelectedWeek(week)
    }
  }

  const AddWeeksToPlan= async (weeks)=>{
   
   if(isDirty ||DetectUnchangedAssignemnts()){
   let dirtyText= "Error! unsaved changes to plan, please save or remove changes before adding additional weeks"
   setErrors(dirtyText);
   return; 
   }
   let text = "Warning!\nAre you sure you want to add "+weeks+" weeks to the patient's coaching plan? These extra weeks can only be removed by a Karuna Admin.";

   if (window.confirm(text) === true)
{
   setEditPlanSettings(true)
    setIsDirty(true);
   setForceLoading(true);
await API.post(`/coaching_plan_track_instances/${instanceID}/add_weeks/?num_weeks=${weeks}`).then((response) => {

        //console.log(response);
       // redoState();   
       localStorage.setItem("week",selectedWeek );
       RefreshPage();
     
        
          
    }).catch(error => {
        console.error(error)
        setErrors(error.message);
       setIsDirty(false)


    })  
}
    
  }

  

     ///////////////////
    //    RENDER    //
    /////////////////

    const renderState=()=>{
       // UnstartedCheck();
      //console.log(selectedWeek)
      //console.log(forceLoading)
        if(forceLoading||selectedWeek===null)
        {
            //console.log("option1")

            return  (<div className=" w-full h-full justify-center items-center inline-flex flex-col	">
        <TailSpin	
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                  />
                  <br /> <br />
                  <div/>
              <h4 className="font-['Inter']">Loading...</h4>
        </div>)
        }
      //  console.log(coachingPlans)
      if(coachingPlans&&coachingPlans.length===0&&currentWeek===-1 &&activityStats!==null)
      {
      //  console.log(templatePlan)
      //  console.log(timeZones)
      //  console.log(1)
           
       return <NewPlan name={patientName} coachingtracks={coachingTracks} templatePlan={templatePlan} setTemplatePlan={setTemplatePlan} timezones={timeZones} setTimeZone={setTimeZone} setIsDirty={setIsDirty} startNewPlan={HandleSubmit} />
      }
      else if(coachingPlans&&coachingPlans.length!==0 &&selectedWeek!==null&&activityStats!==null)
      {

        if(coachingPlans[0].track_name==='Default Week Zero')
        {
           
            return <DefaultZeroView name={patientName} coachingtracks={coachingTracks} templatePlan={templatePlan} setTemplatePlan={setTemplatePlan} timezones={timeZones} setTimeZone={setTimeZone} setIsDirty={setIsDirty} startNewPlan={HandleSubmit} setEdit={setEditPlanSettings} />
        }
        else
        {
            //console.log(trackInstance)

        if(trackInstance.state==="Active"){
            //console.log("option3")

            if(currentWeek===selectedWeek)
            {
               
               return  <CurrentWeekView setOptionBonus={UpdateBonusOptions} setOptionRoutine={UpdateRoutineOptions} constPlan={constPlan} constRoutine={constRoutine} addWeeks={AddWeeksToPlan}  setActivityBonus={UpdateBonusAcitivtyValue} setDurationBonus={UpdateBonusTimerValue} setActivityPersonal={UpdatePriorityValue} setDurationPersonal={UpdatePriorityTimerValue} activityStats={activityStats} changeWeek={ChangeWeek} weekOptions={weeks} key={personalRoutine} coachingPlan={coachingPlans} currentTemplate={templatePlan}  name={patientName} selectedWeek={selectedWeek} personalRoutine={personalRoutine} ToggleRoutine={TogglePersonalRoutine} endPlan={EndPlan} saveChanges={HandleSubmit} completeWeek={StartWeek} templates={coachingTracks} setTemplate={SubmitToUpdatePlanSettings} routineScoreData={bestPriority} />
            }
            else if(currentWeek<selectedWeek)
            {
            return  <FutureWeekView setOptionBonus={UpdateBonusOptions} setOptionRoutine={UpdateRoutineOptions}  constPlan={constPlan}  setActivity={UpdateBonusAcitivtyValue} setDuration={UpdateBonusTimerValue} activityStats={activityStats} addWeeks={AddWeeksToPlan} goToCurrentWeek={GoToCurrentWeek} changeWeek={ChangeWeek} saveChanges={HandleSubmit} weekOptions={weeks} name={patientName}  coachingPlan={coachingPlans}  selectedWeek={selectedWeek}/>
            }
            else
            {
            return <PastWeekView  constPlan={constPlan} constRoutine={constRoutine}  activityStats={activityStats} changeWeek={ChangeWeek}  goToCurrentWeek={GoToCurrentWeek} weekOptions={weeks} name={patientName}  coachingPlan={coachingPlans}  selectedWeek={selectedWeek} routineScoreData={bestPriority} personalRoutine={personalRoutine}/>   
            }

        }
        else
        {
            if(currentWeek===selectedWeek)
            {
               return  <CompletedCurrentWeekView constPlan={constPlan} constRoutine={constRoutine} activityStats={activityStats} StartNewPlan={ForceSetupPage} changeWeek={ChangeWeek}  weekOptions={weeks} coachingPlan={coachingPlans}  name={patientName} selectedWeek={selectedWeek} routineScoreData={bestPriority} personalRoutine={personalRoutine}/>
            }
            else if(currentWeek<selectedWeek)
            {
               return  <CompletedFutureWeekView constPlan={constPlan} constRoutine={constRoutine} activityStats={activityStats} StartNewPlan={ForceSetupPage} changeWeek={ChangeWeek}  weekOptions={weeks} coachingPlan={coachingPlans}  name={patientName} selectedWeek={selectedWeek}/>
            }
            else
            {
              return   <CompletedPastWeekView constPlan={constPlan} constRoutine={constRoutine} activityStats={activityStats} StartNewPlan={ForceSetupPage} changeWeek={ChangeWeek}  weekOptions={weeks} coachingPlan={coachingPlans}  name={patientName} selectedWeek={selectedWeek} routineScoreData={bestPriority} personalRoutine={personalRoutine}/>
            }
        }
        }
      }
      else
      {
        //console.log("option2")

        return  (<div className=" w-full h-full justify-center items-center inline-flex flex-col	">
        <TailSpin	
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                  />
                  <br /> <br />
                  <div/>
              <h4 className="font-['Inter']">Loading...</h4>
        </div>)
      }

    }

  return (
    <PatientProfile  forceOverlFlow={true} errors={errors} successes={successes}>
        

      {(coachingPlans===null|| currentWeek===null||bestPriority==null || stateView===null )?
 (<div className=" w-full h-full justify-center items-center inline-flex flex-col	">
        <TailSpin	
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                  />
                  <br /> <br />
                  <div/>
              <h4 className="font-['Inter']">Loading...</h4>
        </div>):<React.Fragment ><div className='		w-full h-full'>{stateView}</div></React.Fragment>}

  
    </PatientProfile>
  )
}

export default withRouter(EditCoachingPlan)