import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import history from './history'

import cookie from 'react-cookies';
import Login from './pages/Login';
import Gallery from './pages/Gallery';
import PropTypes from 'prop-types';
import EditPatient from './pages/EditPatient';
import  EditCoachingPlan  from './pages/EditCoachingPlan';
import PatientActivityPage from './pages/PatientActivityPage';
import PatientReviewReportPage from './pages/PatientReviewReportPage';
import PatientProfileRedirect from './pages/PatientProfileRedirect';
import ResetPassword from './pages/ResetPassword';
import UserSettings from './pages/UserSettings';
import EditUser from './pages/EditUser';
import SessionPage from './pages/SessionPage';
import PatientOutcomeGenerationPage from './pages/PatientOutcomeGenerationPage';
import PatientOutcomeVerificationPage from './pages/PatientOutcomeVerificationPage';
import ChangePassword from './pages/ChangePassword';
import SetPassword from './pages/SetPassword';
import Maintenance from './pages/Maintenance';
import Downloader from './pages/Downloader';
import DownloadZip from './pages/DownloadZip';
import API from './services/API';
import ValidateEmail from './pages/ValidateEmail';
import ReportReviewPage from './pages/ReportReviewPage';
import ExternalReportsPage from './pages/ExternalReportsPage';

const App = ({ }) => {
	const [loggedIn, setLoggedIn] = useState(cookie.load('token'));
	useEffect(() => {
		console.log('Token: ' + cookie.load('token'))
		if (!loggedIn && cookie.load('token')) {
			console.log("setting logged in cause token is " + cookie.load('token'))
			setLoggedIn(true);
		}
		else if (loggedIn && !cookie.load('token')) {
			console.log("setting logged out");
			setLoggedIn(false);
		}
		else if (!loggedIn && !cookie.load('token')) {
			console.log("logged out");
			setLoggedIn(false);
		}

	}, [loggedIn]);


	const ProtectedRoute = ({ component: Component, ...rest }) => {
		return (
			<Route
				{...rest}
				render={(props) =>
					(loggedIn ?
						<Component {...props} /> :
						<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
					)}
			/>
		)
	}

	const AuthenticatedRoute = ({ component: Component, ...rest }) => {
		return (
			<Route
				{...rest}
				render={(props) =>
					(!loggedIn ?
						<Component {...props} /> :
						<Redirect to={{ pathname: '/' }} />
					)}
			/>
		)
	}

	API.interceptors.response.use(response => {
		return response;
	 }, error => {
		console.log(error)
		if(error.response===undefined){
			console.log("401!!")
		cookie.remove('token', { path: '/' });
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
		localStorage.clear();
		if ( !document.URL.includes("login") ) {
			history.push('/login');
		}
		return error;
		}
		else{
		if(error.response.status!==undefined){
	   if (error.response.status === 401) {
		console.log("401!!")
		cookie.remove('token', { path: '/' });
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
		localStorage.clear();
		if ( !document.URL.includes("login") ) {
			history.push('/login');
		}
	   }
	   if (error.response.status === 503) {
		history.push('/maintenance');

	   }
	}
}
	   return error;
	 });


	ProtectedRoute.propTypes = {
		component: PropTypes.any,
		location: PropTypes.any,
	};

	return (
		<BrowserRouter forceRefresh={true} history={history} >
			<Switch>
				<Route exact path='/login' component={Login} />
				<Route exact path='/maintenance' component={Maintenance} />
				<ProtectedRoute path='/' exact component={Gallery} />
				<ProtectedRoute exact path='/patient/:id' exact component={PatientProfileRedirect} />
				<ProtectedRoute exact path='/patient/:id/edit' exact component={EditPatient} />
				<ProtectedRoute exact path='/patient/:id/coaching-plan' exact component={EditCoachingPlan} />
				<ProtectedRoute exact path='/patient/:id/activity' exact component={PatientActivityPage} />
				<ProtectedRoute exact path='/patient/:id/reports' exact component={PatientReviewReportPage} />
				<ProtectedRoute exact path='/create-patient' exact component={EditPatient} />
				<ProtectedRoute exact path='/user' exact component={UserSettings} />
				<ProtectedRoute exact path='/user/edit' exact component={EditUser} />
				<ProtectedRoute exact path='/patient/:patientId/day/:dayName' exact component={SessionPage} />
				<ProtectedRoute exact path='/patient/:id/outcomes' exact component={PatientOutcomeGenerationPage} />
				<ProtectedRoute exact path='/outcomes/:outcomeId' exact component={PatientOutcomeVerificationPage} />
				<ProtectedRoute exact path ='/reports' exact component={ReportReviewPage}/>
				<ProtectedRoute exact path ='/reportsExternal' exact component={ExternalReportsPage}/>
				<Route exact path='/password/reset/confirm/:uid/:token' exact component={SetPassword} />
				<Route exact path='/account-confirm-email/:key/' exact component={ValidateEmail} />
				<Route exact path='/report/:key/' exact component={Downloader} />
				<Route exact path='/reports/:key/' exact component={DownloadZip} />
				<Route exact path='/password/reset' exact component={ResetPassword} />
				<ProtectedRoute exact path='/password/change/' exact component={ChangePassword} />

			</Switch>
		</BrowserRouter>
	);
}



export default App;
