import React, { useState, useEffect } from 'react';
import { withRouter, useParams, Prompt } from 'react-router';
const OptionDropDown = ({options,borderState,index}) => {

    const [dropdownOpen,setDropDownOpen]=useState(false);

    const camelCaseParser=(text)=>{
      return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
    }

    //<span className='tooltip rounded shadow-lg  whitespace-normal	 break-normal min-w-[10vw] max-w-[20vw] [@media(max-width:1200px)]:max-w-[40vw] [@media(min-width:1600px)]:max-w-[20vw] w-max bottom-[90%] left-[25%]    '>{option.description}</span>
console.log(index)
    const checks=options&&options.length&&options.map((option,i) => {
        return <label key={i} className="  px-4 flex  cursor-pointer gap-x-2 py-1 text-xs  text-gray-700 leading-tight font-semibold font-['Inter']  ">

        <input type="checkbox" checked={option.value} onChange={(e)=>{  option.onchange([option.name,e.target.checked,options])}} className=' cursor-pointer p-0.5 bg-gray-50 rounded border border-blue-600 justify-center items-center flex'/>
    <div className='capitalize'>{camelCaseParser( option.name)}</div>
    <span className=' relative has-tooltip  '>
    <img className="w-3.5 h-3.5  " src={"/icons/info-circle.svg"}></img>
    <span className={'tooltip rounded shadow-lg  whitespace-normal	 break-normal min-w-[10vw] max-w-[25vw] [@media(max-width:1200px)]:max-w-[40vw] [@media(min-width:1600px)]:max-w-[25vw] w-max bottom-[90%]    '+(window.screen.availWidth>1200?(index===0||index===2||index===4?"left-[50%] ":"right-[50%] "):("[@media(max-width:1200px)]:right-[50%]  "))}>{option.description}</span>

</span>
        </label>
    })
  


  return (
    <React.Fragment>
<div  className="h-[46px] flex-col justify-start items-start inline-flex">
<button onClick={()=>{setDropDownOpen(!dropdownOpen)}} className={"text-slate-700 grow shrink w-11 mt-1    bg-white rounded-lg shadow border  justify-center items-center  inline-flex "+(borderState)}>
<img className=" h-5 w-5   " src="/icons/options.svg" />

  </button>
  {dropdownOpen&&<div x-show="dropdownOpen" onClick={()=>{setDropDownOpen(!dropdownOpen)}} className="fixed inset-0 h-full w-full z-10"></div>}

{dropdownOpen&&<div  className="absolute columns-1  mt-[46px] py-2 w-64 bg-white rounded-md shadow-xl z-20">
  
  {checks!==null&&checks!==undefined&&checks.length>0&&checks}
  </div>}

</div>
    </React.Fragment>
  )
}

export default OptionDropDown