import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Spacer from '../components/Spacer';
import Page from '../components/Page';
import Input from '../components/input/Input';
import API from '../services/API';
import { useHistory } from "react-router-dom";
import { TailSpin	 } from  'react-loader-spinner'
import moment from 'moment';

const ReportReviewPage = () => {

    const [errors, setErrors] = useState(null);
    const [userIsClinician, setUserIsClinician] = useState(false);
    const [loading, setLoading] = useState(true);

	const [patients, setPatients] = useState(null);
    const [currentPatient, SetCurrentPatient] = useState(-1);
	const [outcomeReports, setOutcomeReports] = useState(null);
    const [filteredOutcomeReports, setFilteredOutcomeReports] = useState(null);


	const [masterPercentage, setMasterPercentage] = useState(0);
  const [slavePercentage, setSlavePercentage] = useState(0);
  const[filter, setFilter]=useState(null)
  const [filteredpatients, setFilteredPatients] = useState(null);
  const [unapproved,setUnapproved] = useState(0);

  const[width,setWidth]= useState(null);
  const tableRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

const handleResize = () => {
    setDimensions({
    width: window.innerWidth,
    height: window.innerHeight,
    });
  }
useEffect(() => {
  window.addEventListener("resize", handleResize, false);
}, []);

useEffect(() => {
  }, [dimensions]);

  const divStyle = {
	width: (width<window.innerWidth? "fitContent":"90%")

  }
  useEffect(() => {
    console.log(width)
}, [divStyle]);



useEffect(()=>{
    //setDivStyle({	width: width!==null?(width<window.screen.width? "fitContent":"90%"):("90%")})
},[width])

useEffect(() => {
    console.log(tableRef.current)

    if(tableRef.current!==null)
    {
        console.log(tableRef.current)
        console.log(tableRef.current.offsetWidth);
        setWidth(tableRef.current.offsetWidth)
        

    }
}, [tableRef.current,loading]);



    const breadcrumbLinks = () => {


        return [
            {
                label: "Reports",
                url: "/reports"
            }
            
        ]
    
    


}




useEffect(() => {

		
    API.get(`/patients/?pending_outcome_reports=${true}`)
        .then(response => {

            //setPatients(response.data.results);
            var p= {}
            response.data.results.forEach(e=>{p[e.id]=e})
            setPatients(p)
            let ids = response.data.results.map(a => a.id);
            API.get(`/outcome_reports/?reviewable=true`)
        .then(response => {
            //var reports = response.data.results.filter(e=>ids.includes(e.patient_id))      
            var reports = response.data.results   
           // reports= reports.sort((a, b) =>(Number.parseInt(a.included_weeks) !== Number.parseInt(b.included_weeks)?( Number.parseInt(a.included_weeks) < Number.parseInt(b.included_weeks) ? 1 : -1):(moment(a.prepared_date).isBefore( moment(b.prepared_date))?1:-1)))
  console.log(reports)
            setOutcomeReports(reports);
            var unapprovedCount=0;
            reports.forEach(e=>{
                if(!e.approved)
                {
                    unapprovedCount++;
                }
            })
            setUnapproved(unapprovedCount);
            setLoading(false)
        })
        .catch(
            error => {
                setErrors(error)
                console.error(error)
                setOutcomeReports([]);
            }
            );
        })
        .catch(
            error => {
                setErrors(error)
                console.error(error)
                setPatients([]);
            }
            );
}, []);

useEffect(() => {
    if(outcomeReports)
    {
        var filtered=[]
        if(filter)
        {
            var f=filter.trim();
            f=f.toLowerCase();
            filtered=outcomeReports.filter(r=>{
             
                return r.patient_name.toLowerCase().includes(f)
            })
            console.log( filtered)

        //  filtered= filtered.sort((a, b) =>(Number.parseInt(a.included_weeks) !== Number.parseInt(b.included_weeks)?( Number.parseInt(a.included_weeks) < Number.parseInt(b.included_weeks) ? 1 : -1):(moment(a.prepared_date).isBefore( moment(b.prepared_date))?1:-1)))
            console.log( filtered)

            setFilteredOutcomeReports(filtered);

        }
        else
        {
            setFilteredOutcomeReports(outcomeReports)
        }

        
    }
   
}, [outcomeReports,filter]);
let history = useHistory();



const LoadPage=(id)=>{

    if(id){

    history.push(`${id && `/outcomes/${id}`}`);
    }

}


const RenderTable = () => {
    var t= []
    for(var i=0;i<filteredOutcomeReports.length;i++){
        let x =(filteredOutcomeReports[i].id)
        let postProgramReport = filteredOutcomeReports[i].is_post_program_checkin!==undefined && filteredOutcomeReports[i].is_post_program_checkin===true

        let name = filteredOutcomeReports[i].patient_name.split(" ")[0].length<15?filteredOutcomeReports[i].patient_name:(filteredOutcomeReports[i].patient_name.split(" ")[0]+" "+filteredOutcomeReports[i].patient_name.split(" ")[1][0])
         t.push(<React.Fragment><tr className='whitespace-nowrap border-y-2 even:bg-white odd:bg-white	' >
    <td className="whitespace-nowrap border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-900 font-['Inter'] leading-[18px] ">{filteredOutcomeReports[i].patient_name}</td>
    <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{!postProgramReport?"Week "+filteredOutcomeReports[i].included_weeks:"6 Months Post"}</td>
    <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{patients[filteredOutcomeReports[i].patient_id]!==undefined?patients[filteredOutcomeReports[i].patient_id].coach_name:"\u2014"}</td>
    <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{patients[filteredOutcomeReports[i].patient_id]!==undefined?patients[filteredOutcomeReports[i].patient_id].physician_name:"\u2014"}</td>
    <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{patients[filteredOutcomeReports[i].patient_id]!==undefined?patients[filteredOutcomeReports[i].patient_id].profile.third_party_administrators:"\u2014"}</td>
    <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{patients[filteredOutcomeReports[i].patient_id]!==undefined?patients[filteredOutcomeReports[i].patient_id].profile.insurers:"\u2014"}</td>
    <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{moment(filteredOutcomeReports[i].prepared_date).format("MMMM Do YYYY")}</td>
    <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">
    <button className=" px-3.5 py-2 bg-brand-600 hover:bg-brand-700 rounded-lg shadow border border-brand-600 justify-center items-center gap-2 inline-flex" onClick={()=>{LoadPage(x)}}>
    <img className="relative" src="/icons/file-search-02.svg" />
    <div className="text-white text-sm font-semibold font-['Inter'] leading-tight">Review</div>
</button>
    </td>
  </tr></React.Fragment>)
  }
  return t;

}


  return (
    <Page errors={errors} headerObj={{trail:breadcrumbLinks()}}  internal={true}>
    <Spacer headerObj={{trail:breadcrumbLinks()}} internal={true}/>

    <div className="w-full h-full justify-center items-start inline-flex mt-8">
{       loading===false && filteredOutcomeReports!==null?

<div class={" bg-white h-[90%]   rounded-lg shadow-lg border-2 border-gray-200 flex-col  items-end flex "+(filteredOutcomeReports.length>0?(width<window.innerWidth?"w-[90%]":"w-[90%]"):"w-[90%]")} >
<div className="w-full h-[10%] px-6 pt-5 pb-[19px] justify-start items-center gap-4 inline-flex">
<div className="w-[50%] h-full justify-start items-center gap-2 flex">
    <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7">Reports for Review</div>
    {
    unapproved>0&&<div className="mix-blend-multiply justify-start items-start flex">
        <div className="px-2 py-0.5 bg-slate-50 rounded-2xl justify-center items-center flex">
            <div className="text-center text-blue-800 text-xs font-medium font-['Inter'] leading-[18px]">{unapproved}</div>
        </div>
    </div>
    }
   
    
</div>
<div className="grow shrink basis-0 h-5 px-[25px] justify-end items-center gap-2 flex">
   
</div>


<div className="w-[25%] flex-col justify-start items-start inline-flex">
    <div className="self-stretch h-full flex-col justify-start items-start gap-1.5 flex">
        <div className="self-stretch h-full flex-col justify-start items-start gap-1.5 flex">
            <div className="self-stretch px-3.5 py-1.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-full justify-start items-center gap-2 flex">
                    <img className="w-6 h-6 relative" src="/icons/search.svg"/>
                   
                <Input
                className=" outline-none border-0 focus:outline-none	 active:border-0 focus:border-0 focus:ring-0 	overflow-hidden	w-full grow shrink basis-0 h-6 text-gray-500 text-base font-normal font-['Inter'] leading-normal	"
                type="text"
                name="Search by name"
                onChange={setFilter}
                value={filter}
                required
                formErrors={errors} 
                overrideText={"Search by name"}
                />
                </div>
            </div>
        </div>
    </div>
</div>

            </div>
           {filteredOutcomeReports&&filteredOutcomeReports.length>0? <div class="grid grid-col w-full whitespace-nowrap overflow-y-auto	">
                <div class=" whitespace-nowrap w-full	">
                    <div class="whitespace-nowrap w-full	inline-block align-middle ">
                    <div class=" whitespace-nowrap	w-full">
                        
                        <table class=" divide-gray-200 w-full dark:divide-gray-700 " ref={tableRef}>
                        <thead className='whitespace-nowrap w-full	'>
                            <tr className='shadow-[inset_0_1px_0_#d3d3d3,inset_0_-1px_0_#d3d3d3] bg-gray-50 whitespace-nowrap	sticky top-0 z-[2]'>
                            <th  scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">Patient</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Report Week</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Coach</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Physician</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">TPA</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Insurer</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Created</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Actions</th>
                            </tr>
                        </thead>
                        <tbody className='whitespace-nowrap	'>
                            <React.Fragment>
                        {
                            filteredOutcomeReports&&patients&&  RenderTable()
                        }
                        </React.Fragment>
                        </tbody>
                    
                        </table>
                        
                    </div>
                    </div>
                </div>
            </div>:	<div className=" w-full h-full justify-center items-center inline-flex flex-col	">
		                        <p className="px-6 text-gray-900 text-lg font-medium font-['Inter']">No reports found</p>
		</div>
}
        </div>
        :
        <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
	<TailSpin	
				height="100"
				width="100"
				color='grey'
				ariaLabel='loading'
			  />
			  <br /> <br />
			  <div/>
		  <h4 className="font-['Inter']">Loading...</h4>
	</div>
}

    </div>


    

</Page>
    )
}

export default withRouter(ReportReviewPage)