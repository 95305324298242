import React, { useState } from 'react'
import { CheckIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import moment from 'moment';
import SessionDivider from './SessionDivider';
import SessionBonusPanel from './SessionBonusPanel.tsx';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SessionRoutinePanel = ({sessionData, day, week}) => {

  const [opened,setOpened]= useState(false);

  const timeConvert=(raw)=>{
    var times=raw.split(':');
    var hours = times[0];
    var minutes= times[1];
    var seconds= times[2];
    var AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
    var extraSpace=hours.length===1?" ":""
    var finalTime = extraSpace+hours +":" + minutes + AmOrPm; 
    return finalTime;


  }



const GetActivityBadge=(badge,week)=>{
  if(badge!==null){
  
  let color;
  switch(badge)
    {
      case 1:
        color="Bronze";
        break;
      case 2:
        color="Silver";
        break;
      default:
        color="Gold";
        break;
    }
  return `/icons/badges/CoachingPlan_Week${week}${color}.png`
}
else
{
  return `/icons/badges/CoachingPlan_None.png`

}
 
}


const CleanLabels=(label)=>{
  var limit=20;
  if(label.length<=limit)
      {return (
              <div className="text-gray-700 text-sm font-medium font-['Inter'] leading-5">{label}</div>

          )
      }
  else
  {
      return(
          <div className="text-gray-700 text-sm font-medium font-['Inter'] relative has-tooltip leading-5 flex"><span className='tooltip rounded shadow-lg p-1 ml-10 mt-4'>{label}</span><div className='hs-tooltip'>{label.substring(0, limit)+"..."}</div></div>

      )
  }
}

const ContextTextColor=(raw)=>{
  switch(true)
    {
      case raw===0:
          return "text-gray-700"
      case raw<1000:
          return "text-yellow-700";
        case raw<2500:
            return 'text-lime-800';
        case raw<4000:
            return 'text-green-light-700';
        default:
             return 'text-success-700';
   
    }

}

const ContextBadgeBackgroundColor=(raw)=>{
  switch(true)
    {
      case raw===0:
          return "bg-gray-50"
      case raw<1000:
        return "bg-yellow-50";
      case raw<2500:
          return 'bg-lime-50';
      case raw<4000:
          return 'bg-green-light-50';
      default:
           return 'bg-success-50';
   
    }

}

const ContextBarColor=(raw)=>{
  switch(true)
    {
     
      case raw===0:
          return "bg-gray-400"
      case raw<1000:
          return "bg-yellow-300";
        case raw<2500:
            return 'bg-lime-300';
        case raw<4000:
            return 'bg-green-light-300';
        default:
             return 'bg-success-300';
   
    }

}

const RomBarColor=(raw,type)=>{
  if(type.includes("Back")){
  switch(true)
    {
     
      case raw<=15:
          return "bg-error-300"
      case raw<=60:
          return "bg-warning-300";
        case raw<=70:
            return 'bg-yellow-300';
        case raw<=80:
            return 'bg-green-light-300';
        default:
             return 'bg-success-300';
   
    }
  }
  else{
      switch(true)
      {
       
          case raw<30:
              return "bg-error-300"
          case raw<120:
              return "bg-warning-300";
            case raw<145:
                return 'bg-yellow-300';
            case raw<165:
                return 'bg-green-light-300';
            default:
                 return 'bg-success-300';
     
      }
  }

}

const ContextLevel=(raw)=>{
  let level =""
  switch(true)
    {
      case raw===0:
          level="None";
          break;
      case raw<1000:
        level="Keep Going ";
        break;
      case raw<2500:
        level= 'Good';
        break;
      case raw<4000:
        level='Great'
        break;
      default:
        level="Excellent"
        break;
    }
    
  return level
}

const ContextTooltip=(raw)=>{
  let level =""
  switch(true)
    {
     
      case raw<1000:
        level="0-999";
        break;
      case raw<2500:
        level= '1000-2499';
        break;
      case raw<4000:
        level='2500-3999'
        break;
      default:
        level="4000-5000+"
        break;
    }
    
  return level
}

const RomBadgeTextColor=(raw,type)=>{
  if(type.includes("Back")){
  switch(true)
    {
     
      case raw<=15:
          return "text-error-700"
      case raw<=60:
          return "text-warning-700";
        case raw<=70:
            return 'text-yellow-700';
        case raw<=80:
            return 'text-green-light-700';
        default:
             return 'text-success-700';
   
    }
  }
  else{
      switch(true)
      {
       
          case raw<30:
              return "text-error-700"
          case raw<120:
              return "text-warning-700";
            case raw<145:
                return 'text-yellow-700';
            case raw<165:
                return 'text-green-light-700';
            default:
                 return 'text-success-700';
     
      }
  }

}

const RomBadgeColor=(raw,type)=>{
  if(type.includes("Back")){
  switch(true)
    {
     
      case raw<=15:
          return "bg-error-50"
      case raw<=60:
          return "bg-warning-50";
        case raw<=70:
            return 'bg-yellow-50';
        case raw<=80:
            return 'bg-green-light-50';
        default:
             return 'bg-success-50';
   
    }
  }
  else{
      switch(true)
      {
       
          case raw<30:
              return "bg-error-50"
          case raw<120:
              return "bg-warning-50";
            case raw<145:
                return 'bg-yellow-50';
            case raw<165:
                return 'bg-green-light-50';
            default:
                 return 'bg-success-50';
     
      }
  }

}

const RomLevel=(raw,type)=>{
  if(type.includes("Back")){
      switch(true)
        {
         
          case raw<=15:
              return "Sev. Limited"
          case raw<=60:
              return "Mod. Limited";
            case raw<=70:
                return 'Min. Limited';
            case raw<=80:
                return 'Functional';
            default:
                 return 'Full Function';
       
        }
      }
      else{
          switch(true)
          {
           
              case raw<30:
                  return "Sev. Limited"
                  case raw<120:
                      return "Mod. Limited";
                  case raw<145:
                          return 'Min. Limited';
                  case raw<165:
                      return 'Functional';
                  default:
                      return 'Full Function';           
          }
      }
}

const RomTooltip=(raw,type)=>{
  if(type.includes("Back")){
      switch(true)
        {
         
          case raw<=15:
              return "Severely Limited 0°-15°"
          case raw<=60:
              return "Moderately Limited 15°-60°";
            case raw<=70:
                return 'Minimally Limited 60°-70°';
            case raw<=80:
                return 'Functional 70°-80°';
            default:
                 return 'Full Function 80°-90°';
       
        }
      }
      else{
          switch(true)
          {
           
                  case raw<30:
                      return "Severely Limited 0°-30°"
                  case raw<120:
                      return "Moderately Limited 30°-120°";
                  case raw<145:
                      return 'Minimally Limited 120°-145°';
                  case raw<165:
                      return 'Functional 145°-165°';
                  default:
                      return 'Full Function 165°-180°';
          }
      }
}





const weeklyBestDecorator=()=>{
  return (
      <div className=" mix-blend-multiply justify-start items-start inline-flex">
  <div className="pl-1.5 pr-2 py-0.5 bg-brand-50 rounded-2xl justify-center items-center gap-1 flex">
      <img className="w-3 h-3 relative" src='/icons/award-blue.svg' />
      <div className="text-center text-brand-700 text-xs font-medium font-['Inter'] leading-[18px]">Weekly Best</div>
  </div>
</div>
  )

}

const allTimeBestDecorator=()=>{
  return (
      <div className=" mix-blend-multiply justify-start items-start inline-flex">
  <div className="pl-1.5 pr-2 py-0.5 bg-brand-50 rounded-2xl justify-center items-center gap-1 flex">
      <img className="w-3 h-3 relative" src='/icons/award-blue.svg' />
      <div className="text-center text-brand-700 text-xs font-medium font-['Inter'] leading-[18px]">All-Time Best</div>
  </div>
</div>
  )
}



const RomRow=(label,score)=>{
  var divider=180;
  if(label.includes("Back"))
      {
          divider=90
      }
  return ( 
      <div className="w-full  justify-start items-start gap-2 inline-flex">
          <span className="justify-start items-start gap-2 inline-flex [@media(max-width:1200px)]:w-[25%] w-[20%] [@media(min-width:1600px)]:w-[15%]  ">
          <img className="w-5 h-5 relative flex-col justify-start items-start flex"  src= "/icons/compass.svg"  />
          {CleanLabels(label+(label.includes("Back")?" (Estimate)":""))}
          </span>
          <div className='grow shrink mt-1.5 relative'>
          <div className="w-[100%] absolute h-2 bg-gray-200 rounded-full" />
          <div className={ classNames("h-2 absolute rounded-full",RomBarColor(score,label))} style={{width:(Math.round((score/divider)*100))+'%'}} />
          </div>
          <span className='inline-flex gap-2 text-sm'>
              {Math.round(parseInt( score))}°
              <div className={"px-2 py-0.5 has-tooltip relative  rounded-2xl justify-center items-center flex "+RomBadgeColor(score,label)}>
                  <span className='tooltip rounded shadow-lg p-1 -mt-12'>{RomTooltip(score,label)}</span>
                  <div className={"text-center hs-tooltip  text-xs font-medium font-['Inter'] leading-[18px] text-nowrap "+RomBadgeTextColor(score,label)}>{RomLevel(score,label)}</div>
    </div>
          </span>
      </div>
     
)
  
}



const KarunaRatingRow=(rating)=>{
  if(rating!==null){
  return ( 
      <div className="w-full  justify-start items-start gap-2 inline-flex">
          <span className="justify-start items-start gap-2 inline-flex [@media(max-width:1200px)]:w-[25%] w-[20%] [@media(min-width:1600px)]:w-[15%]  ">
          <img className="w-5 h-5 relative flex-col justify-start items-start flex"  src= "/icons/icon.png"  />
          {CleanLabels("Karuna Rating")}
          </span>
          <div className='grow shrink mt-1.5 relative'>
          <div className="w-[100%] absolute h-2 bg-gray-200 rounded-full" />
          <div className={ classNames("h-2 absolute rounded-full",ContextBarColor(rating.score))} style={{width:(Math.round((rating.score/5000)*100))+'%'}} />


          </div>

          <span className='inline-flex gap-2 text-sm'>
              {rating.score}
          <div className={"px-2 py-0.5 has-tooltip relative rounded-2xl justify-center items-center flex "+ContextBadgeBackgroundColor(rating.score)}>
          <span className='tooltip rounded shadow-lg p-1 -mt-12'>{ContextTooltip(rating.score)}</span>
              <div className={"text-center hs-tooltip  text-xs font-medium font-['Inter'] leading-[18px] text-nowrap "+ContextTextColor(rating.score)}>{ContextLevel(rating.score)}</div>
          </div>
          <span>{rating.top_alltime&&allTimeBestDecorator()}</span>
            <span>{rating.top_weekly&&weeklyBestDecorator()}</span>
          </span>
      </div>
     
) 
 }
else{
  return ( 
    <div className="w-full  justify-start items-start gap-2 inline-flex">
        <span className="justify-start items-start gap-2 inline-flex [@media(max-width:1200px)]:w-[25%] w-[20%] [@media(min-width:1600px)]:w-[15%]  ">
        <img className="w-5 h-5 relative flex-col justify-start items-start flex"  src= "/icons/icon.png"  />
        {CleanLabels("Karuna Rating")}
        </span>
        <div className='grow shrink mt-1.5 relative'>
        <div className="w-[100%] absolute h-2 bg-gray-200 rounded-full" />

        </div>

        <span className='inline-flex gap-2 text-sm'>
            
        None

        </span>
    </div>
  )
  
}
}

const RenderCompleted=(completed)=>{
  if(completed)
    {
      return (
        <div className="mix-blend-multiply justify-start items-start flex">
        <div className="pl-1.5 pr-2 py-0.5 bg-success-50 rounded-2xl justify-center items-center gap-1.5 flex">
            <div className="w-2 h-2 relative">
                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-success-500 rounded-full" />
            </div>
            <div className="text-center text-success-700 text-xs font-medium font-['Inter'] leading-[18px]">Completed</div>
        </div>
      </div>
      )
    }
    else{
      if(sessionData.progress_snapshot!==undefined){
        var hasProgress=false;
        Object.values(sessionData.progress_snapshot).forEach(e=>{
         if( Object.values(e)[0]===1){hasProgress=true;}
        })
        if(hasProgress){
      return (
        <div className="mix-blend-multiply justify-start items-start flex">
        <div className="pl-1.5 pr-2 py-0.5 bg-lime-50 rounded-2xl justify-center items-center gap-1.5 flex">
            <div className="w-2 h-2 relative">
                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-lime-400 rounded-full" />
            </div>
            <div className="text-center text-lime-900 text-xs font-medium font-['Inter'] leading-[18px]">Partially Completed</div>
        </div>
      </div>
      )}
      else{
        return (
          <div className="mix-blend-multiply justify-start items-start flex">
          <div className="pl-1.5 pr-2 py-0.5 bg-warning-50 rounded-2xl justify-center items-center gap-1.5 flex">
              <div className="w-2 h-2 relative">
                  <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-warning-500 rounded-full" />
              </div>
              <div className="text-center text-warning-700 text-xs font-medium font-['Inter'] leading-[18px]">Incomplete</div>
          </div>
        </div>
        )

      }
    }
      else{
        return (
          <div className="mix-blend-multiply justify-start items-start flex">
          <div className="pl-1.5 pr-2 py-0.5 bg-warning-50 rounded-2xl justify-center items-center gap-1.5 flex">
              <div className="w-2 h-2 relative">
                  <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-warning-500 rounded-full" />
              </div>
              <div className="text-center text-warning-700 text-xs font-medium font-['Inter'] leading-[18px]">Incomplete</div>
          </div>
        </div>
        )

      }
    }
}





const SnapShotWarning=()=>{
  return (
      <div className=" w-full pb-5 justify-start items-start gap-3 inline-flex">
<img className="w-5 h-5  relative flex-col justify-start items-start  flex" src="/icons/warning_icon.svg"/>
<div className="text-amber-700 text-sm font-normal font-['Inter'] pt-0.5	 leading-tight">Sessions prior to 10th June 2024 cannot display complete snapshots of the routine when it was only partially completed.</div>
</div>
  )
}

const parseCamelCaseTitle=(title)=>{
  return title.split(/(?=[A-Z])/).join(' ');

}

const RenderRoms=(roms)=>{
  var render=[]
  
  Object.keys(roms).forEach(rom => {
      render.push(  RomRow(parseCamelCaseTitle(rom),roms[rom]));
      
  });
  return render;
}


const RenderActiviites=()=>{
  var activities=[];
  if(sessionData&&sessionData.activities){
    Object.keys( sessionData.activities).forEach((a,index)=>{
    var act= sessionData.activities[a];
    if(act.instances!==undefined){
    for(var i=0; i<act.instances.length; i++){
      act.instances[i].name= act.name;
      act.instances[i].type = act.type;
  
      activities.push( ( <SessionBonusPanel activity={act.instances[i]} routine={true}/>))
      
     

  }
  }
  else{
    activities.push( <SessionBonusPanel activity={act} routine={true}/>)
    
  }
  if(index!==sessionData.activities.length-1){
    activities.push( (  <SessionDivider label={""} activityDivider={true}/>))
    }
  })
}
return activities;
}








  return (
    <div className="w-full  justify-start items-center gap-5 inline-flex">
    <div className=" self-stretch  flex-col justify-start items-end gap-2.5 inline-flex">
        <div className="text-right text-gray-500 text-sm font-medium font-['Inter'] leading-tight">{sessionData.start_time!=null?timeConvert(sessionData.start_time):"n/a"}</div>
        <div className="w-px grow shrink basis-0 bg-gray-400" />
        <div className="text-right text-gray-500 text-sm font-medium font-['Inter'] leading-tight">{sessionData.end_time!=null?timeConvert(sessionData.end_time):"n/a"}</div>
    </div>
    {<div className="grow shrink w-full basis-0 px-6 py-[15px] rounded-[10px] shadow border border-gray-200 flex-col justify-start items-start gap-[15px] inline-flex">
        <div className="self-stretch justify-start items-center gap-[15px] inline-flex">
            <div className="text-gray-900 text-xl font-semibold font-['Inter'] leading-[30px]">Personal Routine</div>
            {sessionData.iteration!==undefined&&sessionData.iteration!==null&& sessionData.routine_completed?<div className='has-tooltip relative inline-flex 	'>
                                        <span className='tooltip rounded shadow-lg ml-5 -mt-5 '>{`Completion ${sessionData.iteration}`}</span>
                                        <img className="w-7 h-7" src={GetActivityBadge(sessionData.iteration,week)} />
                                    </div> :<img className="w-7 h-7" src={GetActivityBadge(null)} />}    
                                           {RenderCompleted(sessionData.routine_completed)}
            <div className="grow shrink basis-0 h-6" />
            <button onClick={()=>{setOpened(!opened)}}>
            {opened?<ChevronUpIcon className="h-5 w-5 text-gray-700 relative" aria-hidden="true" />:<ChevronDownIcon className="h-5 w-5 text-gray-700 relative" aria-hidden="true" />}
            </button>
        </div>
        {sessionData.rating?KarunaRatingRow(sessionData.rating):KarunaRatingRow(null)}

    
       {opened&& <span className='w-full h-full justify-center items-center inline-flex flex-col gap-2.5'>
       { console.log(sessionData)}
        {sessionData.roms&&RenderRoms(sessionData.roms)}

       <SessionDivider label={"Activities"} activityDivider={true}/>
       {moment(day).isBefore(moment("2024-6-10"))? SnapShotWarning():<span></span>}
       {RenderActiviites()}
      

        </span>}
        
  </div>}
</div>
  )
}

export default SessionRoutinePanel