import React from 'react';
import API from '../services/API';
import { useHistory } from "react-router-dom";
import Page from '../components/Page';
import { withRouter } from 'react-router';

import cookie from 'react-cookies';


const Maintenance = ({}) => { 
	let content = window.Content;

	let history = useHistory();		

	API.interceptors.response.use(response => {
		return response;
	 }, error => {
	   if (error.response.status === 401) {
		const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";
	
		console.log(401)
		cookie.remove('token');
			cookie.remove('csrftoken', { path: '/' });
			cookie.remove('role', { path: '/' });
			cookie.remove('user', { path: '/' });
		localStorage.clear();
		history.push('/login');
	   }
	   return error;
	 });

	 
	return (
		<Page title="Maintence Page" showMenu={false}>

			<div className="gallery">
				<h3>Karuna backend is currently under maintenance, please try using the portal again later or contact administrator </h3>
				<br />	<br />
			</div>

		</Page>
		
	);
}

export default withRouter( Maintenance);


