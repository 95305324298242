import React, { useState, useEffect } from 'react';
import { withRouter, useParams, Prompt } from 'react-router';
import CustomDropDown from '../CustomDropDown';
const NewPlan = ({name=null, coachingtracks=null,setTemplatePlan=null, timezones=null, setTimeZone=null, setIsDirty=null, startNewPlan=null}) => {

    const [init,SetInit]= useState(true);

        useEffect(() => {
            console.log(coachingtracks)
            console.log(timezones)
           if(init&&coachingtracks!==null&&timezones!==null)
           {
            
            setTemplatePlan(coachingtracks[0].value)
            setTimeZone(timezones[0].value)
            SetInit(false)
           }
      }, [coachingtracks,timezones]);

    const HandleTemplate=(value)=>{
        setTemplatePlan(value);
    }

    const HandleTimeZone=(value)=>{

        setTimeZone(value);
    }


  return (
<div className="w-full  p-6 flex-col justify-center items-center gap-y-9 inline-flex" key={startNewPlan}>
    <div className="self-stretch  flex-col justify-center items-center gap-y-5 flex">
        <div className="w-14 h-14 p-3.5 rounded-xl shadow border border-gray-200 justify-center items-center inline-flex">
            <img className="w-7 h-7 relative flex-col justify-start items-start flex" src='/icons/calendar-plus-01.svg'/>
        </div>
        <div className="w-3/4 text-wrap  text-center text-gray-900 text-3xl font-semibold font-['Inter'] ">Set Up {name!==null&&(name.split(" ")[0])}’s Coaching Plan</div>
        <div className="w-3/4 text-wrap  text-center text-gray-600 text-base font-normal font-['Inter'] ">A coaching plan divides the Karuna program into 12 weeks of VET™ activity assignments, which you can customize to suit your patient’s needs. As you progress the plan, you can view usage data, monitor patient progress, and create outcome reports to document your patient’s recovery.</div>
    </div>
    <div className=" flex-col justify-start items-start gap-2 flex">
        <div className="self-stretch  flex-col justify-start items-start gap-1.5 flex">
            <div className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">Coaching Plan Track</div>
            { coachingtracks!==null&& <CustomDropDown options={coachingtracks} onchange={HandleTemplate} fullwidth={true} />}
              
        </div>
        <div className="self-stretch text-wrap text-slate-600 text-sm font-normal font-['Inter'] leading-tight">Start by selecting a template coaching plan based on the patient’s prescription.</div>
    </div>
    <div className=" flex-col justify-start items-start gap-2 flex">
        <div className="self-stretch  flex-col justify-start items-start gap-1.5 flex">
            <div className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">Patient Time Zone</div>
            { timezones!==null&& <CustomDropDown options={timezones} onchange={HandleTimeZone} fullwidth={true} />}
              
        </div>
        <div className="invisible	 self-stretch text-wrap text-slate-600 text-sm font-normal font-['Inter'] leading-tight">Start by selecting a template coaching plan based on the patient’s prescription.</div>

    </div>
    { startNewPlan!==null&&startNewPlan!==undefined?
    <button key={startNewPlan} className="w-80 px-[18px] py-3 bg-brand-600 rounded-lg shadow border border-brand-600 hover:bg-brand-700 hover:border-brand-700 justify-center items-center gap-1.5 inline-flex" onClick={startNewPlan}>
        <div className="px-0.5 justify-center items-center flex">
            <div className="text-white text-base font-semibold font-['Inter'] leading-normal">Create Plan</div>
        </div>
    </button>:<span></span>
}
</div>
  )
}

export default NewPlan